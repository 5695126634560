import {
  getRequest,
  postRequest,
  deleteRequest,
  fileUploadRequest,
  patchRequest,
  deleteBulkRequest,
} from "@networkCall";

import {
  APP_API_KEY,
  INSTANCE_URL,
  itemCount,
  DEVELOPMENT_TYPE,
} from "@constant";

import LocaleStrings from "@language";

// All Exports

export var TRANSACTION_HISTORY = "TRANSACTION_HISTORY";

export function fetchTransactionhistory(session, search, datefilter, callback) {
  let filter =
    search !== ""
      ? encodeURI(`(invoicenumber LIKE '%${search}%')`)
      : datefilter !== ""
      ? encodeURI(
          `(transactiondate > ${datefilter.startdate})AND(transactiondate < ${datefilter.enddate})`
        )
      : "";
  //  let datefilter = datefilter == ''
  //           ? ''
  //           : encodeURI(`((transactiondate > ${datefilter[0]}) AND (transactiondate < ${datefilter[1]}))`);

  var url = `${INSTANCE_URL}/api/v2/thomsondata/_table/transactionhistory?filter=${filter}`;
  // console.log(url)
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        // console.log('data1', data);
        dispatch({
          type: TRANSACTION_HISTORY,
          payload: { data: data.resource },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
