import {
  getRequest,
  postRequest,
  deleteRequest,
  fileUploadRequest,
  patchRequest,
  deleteBulkRequest,
} from '@networkCall';

import {
  APP_API_KEY,
  INSTANCE_URL,
  itemCount,
  DEVELOPMENT_TYPE,
} from '@constant';

import LocaleStrings from '@language';

// All Exports

export var ALLAPPUSERS = 'ALLAPPUSERS';
export var OPEN_REVEALED_MODAL = 'OPEN_REVEALED_MODAL';
export var STORE_APPUSERNAME = 'STORE_APPUSERNAME';
export var STORE_APPUSERDATA = 'STORE_APPUSERDATA';
export var REVEALEDCONTACTS_FOR_SELECTED_APPUSERID =
  'REVEALEDCONTACTS_FOR_SELECTED_APPUSERID';
export var APIUSAGE_DATA = 'APIUSAGE_DATA';

// export function fetchallAppuser(session, startdate, endate, search, callback) {
//   let starDate = startdate + " " + "00:00:00";
//   let endDate = endate + " " + "00:00:00";
//   let filter =
//     search == ""
//       ? encodeURI(`(createdon > ${starDate})AND(createdon < ${endDate})`)
//       : encodeURI(`(name LIKE '%${search}%')`);
//   var url = `${INSTANCE_URL}/api/v2/thomsondata/_table/appuser?filter=${filter}&related=usercontact_by_revealedby`;
//   return (dispatch) => {
//     getRequest(
//       url,
//       session,
//       dispatch,
//       (data) => {
//         dispatch({
//           type: ALLAPPUSERS,
//           payload: { data: data.resource },
//         });
//         callback({ success: 1, data: data });
//       },
//       (error) => {}
//     );
//   };
// }

/**** Open add revealed modal ****/
export function openRevealedmodal(isOpen) {
  return {type: OPEN_REVEALED_MODAL, payload: isOpen};
}

export function revealedbyappusername(appuserid) {
  return {type: STORE_APPUSERNAME, payload: appuserid};
}

export function revealedbyappuserdata(appuserdata) {
  return {type: STORE_APPUSERDATA, payload: appuserdata};
}

export const fetchrevealedContacts = (
  session,
  appuserid,
  pageCount = 0,
  callback,
) => {
  let perPage = 25;
  let offset = pageCount <= 1 ? 0 : (pageCount - 1) * perPage;
  let offsetQuery = `limit=${perPage}&offset=${offset}`;
  let orderBy = `createdon%20DESC`;
  let filter = encodeURI(
    `((revealedby='${appuserid}')OR(dialrevealedby='${appuserid}'))`,
  );

  let url = `${INSTANCE_URL}/api/v2/thomsondata/_table/usercontact?include_count=true${`&filter=${filter}`}&order=${orderBy}&${offsetQuery}`;

  return dispatch => {
    getRequest(
      url,
      session,
      dispatch,
      res => {
        // console.log('res: ', res.resource[0]);
        dispatch({
          type: REVEALEDCONTACTS_FOR_SELECTED_APPUSERID,
          payload: {
            count: res.meta.count,
            data: res.resource,
          },
        });
        callback({
          success: 1,
          count: res.meta.count,
          data: res.resource,
        });
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
};

export function fetchApiusage(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/getapiusagedata`;
  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      response => {
        dispatch({
          type: APIUSAGE_DATA,
          payload: {
            data: response.resource,
          },
        });
        callback({success: 1, result: response});
      },
      error => {
        // console.log(error)
        // callback({ success: 0, message: error });
      },
    );
  };
}

export function fetchallAppuser(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/getplatformusagedata`;
  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      response => {
        dispatch({
          type: ALLAPPUSERS,
          payload: {
            data: response.resource,
          },
        });
        callback({success: 1, result: response});
      },
      error => {
        // console.log(error)
        // callback({ success: 0, message: error });
      },
    );
  };
}
