import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import toast, { Toaster } from "react-hot-toast";
import LocaleStrings from "@language";
import { BaseComponent, LABEL_POSITION_TOP } from "@baseComponent";
import { classNames } from "@commonFunction";
import {
  LoginSocialGoogle,
  LoginSocialLinkedin,
  IResolveParams,
} from "reactjs-social-login";
import { GOOGLEID } from "@constant";
// import { LinkedIn } from "react-linkedin-login-oauth2";
import { useLinkedIn } from "react-linkedin-login-oauth2";

import {
  socialRegisterorLogin,
  sendVerificationemail,
  sendPasswordemail,
  getlinkedinuserEmail,
  getlinkedinuserAccessToken,
  linkedinrefreshdata,
  registrationConfirmationemail,
  // getLinkedinemail,
  //   resetStore,
  //   isForgotPasswordOpen,
  //   validateSocialId,
  //   socialSignupAvailable,
} from "@mainActions";

import { LinkedInLoginButton } from "react-social-login-buttons";

// import {
//   FACEBOOKID,
//   GOOGLEID,
//   CONSUMER_KEY,
//   CONSUMER_SECRET,
//   SOCIALPWD,
// } from "@constant";
import _, { isNull } from "lodash";

// const REDIRECT_URI = "http://localhost:3000/";
const REDIRECT_URI = "https://app.kipplo.com/";
const CLIENT_ID = "86kl703as6nm9y";
const client_secret = "Bb074btSewBvUFj7";

class SocialLogin extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      btnLoading: false,
      showerror: false,
      show: false,
      fbloader: false,
      signup: false,
      btnLoader: false,
      isBtnDisable: false,
      btnlinkedinLoader: false,
      islinkedinBtnDisable: false,
      provider: "",
      profile: null,
      user: {},
      loggedIn: false,
    };
  }

  componentDidMount = () => {
    if (window.opener && window.opener !== window) {
      const code = this.getCodeFromWindowURL(window.location.href);
      window.opener.postMessage({ type: "code", code: code }, "*");
      window.close();
    }
    window.addEventListener("message", this.handlePostMessage);
  };

  handlePostMessage = (event) => {
    if (event.data.type === "code") {
      const { code } = event.data;
      this.getUserCredentials(code);
    }
  };

  getCodeFromWindowURL = (url) => {
    const popupWindowURL = new URL(url);
    return popupWindowURL.searchParams.get("code");
  };

  showPopup = () => {
    // const { clientId, redirectUrl, oauthUrl, scope, state } = LinkedInApi;
    const oauthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${CLIENT_ID}&scope=r_liteprofile%20r_emailaddress&state=123456&redirect_uri=${REDIRECT_URI}`;
    const width = 450,
      height = 730,
      left = window.screen.width / 2 - width / 2,
      top = window.screen.height / 2 - height / 2;
    window.open(
      oauthUrl,
      "Linkedin",
      "menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=" +
        width +
        ", height=" +
        height +
        ", top=" +
        top +
        ", left=" +
        left
    );
  };

  getUserCredentials = (code) => {
    let values = {
      code: code,
    };
    // console.log("code", code);
    this.setState({ btnlinkedinLoader: true });
    this.props.getlinkedinuserAccessToken(values, (callback) => {
      // console.log("call", callback);
      if (callback.success == 1 && callback.data) {
        let val = callback.data.data;

        this.props.linkedinrefreshdata(val, (callbackresponse) => {
          // console.log('callbackresponse', callbackresponse)
          if (callbackresponse && callbackresponse.success == 1) {
            if (callbackresponse.action && callbackresponse.action == "login") {
              this.setState({ isBtnDisable: false });
              this.setState({ btnlinkedinLoader: false });
              toast.success(LocaleStrings.login_success);
              this.props.history.push("/dashboard/main/dashboardmain");
            } else {
              toast.success(LocaleStrings.login_success);
              this.setState({ isBtnDisable: false });
              this.setState({ btnlinkedinLoader: false });
              this.props.history.push("/tellusmore");

              let emailobj = {};

              if (
                val.resource &&
                val.resource.length > 0 &&
                val.resource[0].clientdata
              ) {
                let userdata = val.resource[0]?.clientdata;
                emailobj.email = userdata.email;
                if (emailobj.email != "" && emailobj.email != null) {
                  this.props.registrationConfirmationemail(
                    this.props.session,
                    emailobj
                  );
                }
              }
            }
          } else {
            this.setState({ isBtnDisable: false });
            this.setState({ btnlinkedinLoader: false });
            toast.error(LocaleStrings.common_fail_message);
          }
        });
      } else {
        toast.error(LocaleStrings.common_fail_message + " !Please try again");
        this.setState({ btnlinkedinLoader: false });
      }
    });
  };

  onSocialLoginResolve = (provider, data) => {
    let { session } = this.props;
    if (data && provider) {
      this.setState({ isBtnDisable: true });
      this.setState({ btnLoader: true });
      let values = {};
      values.email = data.email;
      values.socialid = data.sub;
      values.name = data.name;
      values.type = provider;
      let emailobj = {};
      emailobj.email = data.email;
      emailobj.name = data.name;
      this.props.socialRegisterorLogin(values, (callbackresponse) => {
        // console.log('callbackresponse', callbackresponse)
        if (callbackresponse && callbackresponse.success == 1) {
          if (callbackresponse.action && callbackresponse.action == "login") {
            this.setState({ isBtnDisable: false });
            this.setState({ btnLoader: false });
            toast.success(LocaleStrings.login_success);
            this.props.history.push("/dashboard/main/dashboardmain");
            // this.props.registrationConfirmationemail(
            //   this.props.session,
            //   emailobj
            // );
          } else {
            toast.success(LocaleStrings.login_success);
            this.setState({ isBtnDisable: false });
            this.setState({ btnLoader: false });
            this.props.history.push("/tellusmore");
            this.props.registrationConfirmationemail(
              this.props.session,
              emailobj
            );
            // this.props.sendPasswordemail(session, emailobj);
            // this.props.sendVerificationemail(session, emailobj);
            // this.props.history.push('/dashboard/main/dashboardmain');
          }
        } else {
          this.setState({ isBtnDisable: false });
          this.setState({ btnLoader: false });
          toast.error(LocaleStrings.common_fail_message);
        }
      });
    } else {
    }
  };

  // onSocialLoginResolve1 = (provider, data) => {
  //   console.log("provider", provider);
  //   console.log("data", data);

  //   // return false;

  //   let { session } = this.props;

  //   if (!data.error) {
  //     this.setState({ btnlinkedinLoader: true });
  //     // this.props.getLinkedinemail(data.access_token, (callbackresponse) => {

  //     let obj = {
  //       access_token: data.access_token,
  //     };
  //     this.props.getlinkedinuserEmail(session, obj, (callbackresponse) => {
  //       // console.log("callbackresponse", callbackresponse);
  //       // console.log("callbackresponsesuccess", callbackresponse.success);

  //       if (callbackresponse.success == 1) {
  //         let email = callbackresponse.data.userdata.elements[0]["handle~"];
  //         // console.log("email", email);
  //         let values = {};
  //         values.email = email.emailAddress;
  //         values.socialid = data.id;
  //         values.name = data.localizedFirstName + " " + data.localizedLastName;
  //         values.type = provider;
  //         // console.log("values", values);
  //         // return false;
  //         this.props.socialRegisterorLogin(values, (callbackresponse) => {
  //           // console.log('callbackresponse', callbackresponse)
  //           if (callbackresponse && callbackresponse.success == 1) {
  //             if (
  //               callbackresponse.action &&
  //               callbackresponse.action == "login"
  //             ) {
  //               this.setState({ isBtnDisable: false });
  //               this.setState({ btnLoader: false });
  //               toast.success(LocaleStrings.login_success);
  //               this.props.history.push("/dashboard/main/dashboardmain");
  //             } else {
  //               let emailobj = {};
  //               emailobj.email = email.emailAddress;
  //               emailobj.name =
  //                 data.localizedFirstName + " " + data.localizedLastName;
  //               toast.success(LocaleStrings.login_success);
  //               this.setState({ isBtnDisable: false });
  //               this.setState({ btnLoader: false });
  //               this.props.history.push("/tellusmore");
  //               // console.log("session", session);
  //               // this.props.sendPasswordemail(session, emailobj);
  //               // this.props.sendVerificationemail(session, emailobj);
  //               // this.props.history.push('/dashboard/main/dashboardmain');
  //             }
  //           } else {
  //             this.setState({ isBtnDisable: false });
  //             this.setState({ btnlinkedinLoader: false });
  //             toast.error(LocaleStrings.common_fail_message);
  //           }
  //         });
  //       } else {
  //         toast.error(LocaleStrings.common_fail_message + " !Please try again");
  //         this.setState({ btnlinkedinLoader: false });
  //       }
  //     });
  //     // });
  //   } else {
  //     toast.error(LocaleStrings.common_fail_message + "!Please try again");
  //     this.setState({ btnlinkedinLoader: false });
  //   }
  // };

  onLoginStart = () => {};
  linkedInLogin = (linkedInLogin) => {
    // console.log("eee", linkedInLogin);
    // alert("hi");
  };

  render() {
    var { handleSubmit } = this.props;
    var { signup, provider, data } = this.state;

    return (
      <>
        <LoginSocialGoogle
          client_id={GOOGLEID}
          onLoginStart={this.onLoginStart}
          scope="openid profile email"
          discoveryDocs="claims_supported"
          access_type="offline"
          onResolve={({ provider, data }) => {
            this.onSocialLoginResolve(provider, data);
          }}>
          <div className="pt-4">
            <button
              type="button"
              disabled={this.state.isBtnDisable}
              className={classNames(
                this.state.isBtnDisable ? "cursor-not-allowed" : "",
                "btn-white"
              )}>
              <svg
                className={classNames(
                  this.state.btnLoader ? "" : "sr-only",
                  "animate-spin-medium h-5 w-5 rounded-full mx-2"
                )}
                viewBox="0 0 1024 1024"
                focusable="false"
                data-icon="loading"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true">
                <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
              </svg>
              <img className="pr-2" src="../../images/google.png"></img>{" "}
              {LocaleStrings.mainlogin_signingoogle}
            </button>

            {/* <button type="button" className="btn-white">
                            <img className="pr-2" src="../../images/google.png"></img> {LocaleStrings.mainlogin_signingoogle}
                        </button> */}
          </div>
        </LoginSocialGoogle>

        {/* <LoginSocialLinkedin
          // isOnlyGetToken
          // client_id="8643d8ww1h8elr"
          // client_secret={"J731XsQvYj9Rtpd9"}
          client_id="86kl703as6nm9y"
          client_secret={"Bb074btSewBvUFj7"}
          scope={"r_liteprofile r_emailaddress"}
          onLoginStart={this.linkedInLogin}
          redirect_uri={REDIRECT_URI}
          onResolve={({ provider, data }) => {
            this.onSocialLoginResolve1(provider, data);
          }}
          onReject={(err) => {
            console.log("err", err);
          }}>
          <div className="pt-4">
            <button type="button" className="btn-white">
              <svg
                className={classNames(
                  this.state.btnlinkedinLoader ? "" : "sr-only",
                  "animate-spin-medium h-5 w-5 rounded-full mx-2"
                )}
                viewBox="0 0 1024 1024"
                focusable="false"
                data-icon="loading"
                width="1em"
                height="1em"
                fill="currentColor"
                aria-hidden="true">
                <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
              </svg>
              <img className="pr-2" src="../../images/linkedin.png"></img>{" "}
              {LocaleStrings.mainlogin_signinlinkedin}
            </button>
          </div>
        </LoginSocialLinkedin> */}

        <div className="pt-4" onClick={this.showPopup}>
          <button type="button" className="btn-white">
            <svg
              className={classNames(
                this.state.btnlinkedinLoader ? "" : "sr-only",
                "animate-spin-medium h-5 w-5 rounded-full mx-2"
              )}
              viewBox="0 0 1024 1024"
              focusable="false"
              data-icon="loading"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true">
              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
            </svg>
            <img className="pr-2" src="../../images/linkedin.png"></img>{" "}
            {LocaleStrings.mainlogin_signinlinkedin}
          </button>
        </div>

        {/* <LinkedIn
          clientId="8643d8ww1h8elr"
          redirectUri={REDIRECT_URI}
          scope={"r_emailaddress"}
          onSuccess={(code) => {
            this.onSocialLoginResolve1(code, data);
            console.log("c", code);
          }}
          onError={(error) => {
            console.log("e", error);
          }}>
          {({ linkedInLogin }) => (
            <div className="pt-4" onClick={linkedInLogin}>
              <button type="button" className="btn-white">
                <img className="pr-2" src="../../images/linkedin.png"></img>{" "}
                {LocaleStrings.mainlogin_signinlinkedin}
              </button>
            </div>
          )}
        </LinkedIn> */}
        <Toaster />
      </>
    );
  }
}

function validate(values) {
  var errors = {};

  if (!values.email) {
    errors.email = LocaleStrings.login_form_validation_email_required;
  }

  if (!values.password) {
    errors.password = LocaleStrings.login_form_validation_password_required;
  }
  return errors;
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
});

export default reduxForm({
  validate,
  form: "SocialLoginForm",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(mapStateToProps, {
    socialRegisterorLogin,
    sendVerificationemail,
    sendPasswordemail,
    getlinkedinuserEmail,
    getlinkedinuserAccessToken,
    linkedinrefreshdata,
    registrationConfirmationemail,
    // getLinkedinemail,
  })(SocialLogin)
);
