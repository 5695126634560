export const SEARCHED_DATA = 'SEARCHED_DATA';

export var searchedData = (state = null, action) => {
  switch (action.type) {
    case SEARCHED_DATA:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
