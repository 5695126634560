import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {BaseComponent} from '@baseComponent';
import {classNames} from '@commonFunction';
import LocaleStrings from '@language';
import RevealedBy from './revealedbymodal';
import {
  openRevealedmodal,
  revealedbyappuserdata,
  revealedbyappusername,
  fetchrevealedContacts,
} from '@settingsUsageActions';

class UsagePlatformItem extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: '',
      btnLoader: false,
    };
  }
  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }

  openRevealedmodal(e, values) {
    this.props.revealedbyappuserdata(values);
    this.props.revealedbyappusername(values.name);
    this.props.fetchrevealedContacts(
      this.props.session,
      values.appuserid,
      1,
      res => {},
    );
    setTimeout(() => {
      this.props.openRevealedmodal(true);
    }, 1000);
  }

  handleindex = nmbr => {
    if (nmbr % 2 == 0) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    var {values, keyindex} = this.props;

    // console.log('PROPS =>', values);

    let revealedcount = values.totalrevealedcount;
    let businessRevealedCount = values.busemailcount;
    let personalRevealedCount = values.personalemailcount;
    let phoneRevealedCount = values.directdialscount;
    // if (
    //   values.usercontact_by_revealedby &&
    //   values.usercontact_by_dialrevealedby
    // ) {

    //   revealedcount =
    //     parseInt(values.usercontact_by_revealedby.length) +
    //     parseInt(values.usercontact_by_dialrevealedby.length);
    // }

    /*if (values.usercontact_by_revealedby) {
      const personalEmails = values.usercontact_by_revealedby.filter(
        item => item.emails !== null,
      );

      personalRevealedCount = personalEmails.length;

      const businessEmails = values.usercontact_by_revealedby.filter(
        item => item.business_emails !== null,
      );

      businessRevealedCount = businessEmails.length;

      // console.log('personalRevealedCount => ', personalEmails.length);
      // console.log('businessEmailsRevealedCount => ', businessEmails.length);

      phoneRevealedCount = parseInt(
        values.usercontact_by_dialrevealedby.length,
      );

      revealedcount =
        personalRevealedCount + businessRevealedCount + phoneRevealedCount;
    }*/
    let indexisodd1 = this.handleindex(keyindex);
    return (
      <>
        <tr className={classNames(indexisodd1 ? 'bg-white' : 'chatbg', '')}>
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm ">
            <div>{values.name}</div>
            <div className="text-gray-500">{values.email}</div>
          </td>
          <td
            className="whitespace-nowrap py-4  px-4 pr-3 text-sm font-medium cursor-pointer"
            onClick={e => this.openRevealedmodal(e, values)}>
            {revealedcount}
            <div className="text-primary font-thin ">
              {LocaleStrings.setting_usage_view_contact}
            </div>
          </td>
          <td className="whitespace-nowrap py-4  px-4 pr-3 text-sm font-medium cursor-pointer">
            {/* {values.emailcount} */}
            {personalRevealedCount}
          </td>
          <td className="whitespace-nowrap py-4  px-4 pr-3 text-sm font-medium cursor-pointer">
            {/* {values.emailcount} */}
            {businessRevealedCount}
          </td>
          <td className="whitespace-nowrap py-4  px-4 pr-3 text-sm font-medium cursor-pointer">
            {phoneRevealedCount}
          </td>
        </tr>
        <RevealedBy appuserData={values} appuserid={values.appuserid} />
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var {session, user} = state;
  return {
    session,
    user,
  };
}

export default connect(mapStateToProps, {
  openRevealedmodal,
  revealedbyappuserdata,
  revealedbyappusername,
  fetchrevealedContacts,
})(UsagePlatformItem);
