import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import toast, {Toaster} from 'react-hot-toast';
import _ from 'lodash';
import moment from 'moment-timezone';
import renderHTML from 'react-render-html';
import LocaleStrings from '@language';
import {BaseComponent, LABEL_POSITION_TOP} from '@baseComponent';
import {classNames} from '@commonFunction';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  // Form,
  Input,
  Col,
} from 'reactstrap';
// Import constant
import {validateEmail, validatepassword} from '@constant';
import LoginErrorBar from '../starter/errorbar';

import {resetpassword, resetStore} from '@mainActions';

class ResetPassword extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showerror: false,
      showsuccess: false,
      alertMessage: '',
      params: {},
      show: false,
      show1: false,
    };

    // All Bind Functions
    this.changeShow = this.changeShow.bind(this);
    this.changeShow1 = this.changeShow1.bind(this);
  }

  componentDidMount() {
    document.title = LocaleStrings.reset_password_title;

    var url_string = window.location.href;
    var query = url_string.split('?')[1];

    var params = {};
    if (query) {
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
      }
      // console.log("params", params);
      this.setState({params: params});
    }
  }

  changeShow() {
    let {show} = this.state;
    this.setState({show: !show});
  }

  changeShow1() {
    let {show1} = this.state;
    this.setState({show1: !show1});
  }

  /* FORM SUBMIT */
  onFormSubmit(values) {
    this.setState({btnLoader: true});
    if (values.password && values.password.length < 8) {
      this.setState({btnLoader: false});
      toast.error(LocaleStrings.reset_password_length_error);
      return false;
    }
    if (
      values.password == values.confirm_password &&
      this.state.params.code != '' &&
      this.state.params.email != ''
    ) {
      values.code = this.state.params.code ? this.state.params.code : '';
      values.email = this.state.params.email ? this.state.params.email : '';
      this.setState({loading: true});
      // console.log('val', values)
      this.props.resetpassword(values, response => {
        if (response.success === 0) {
          this.setState({btnLoader: false});
          if (
            response &&
            response.message &&
            response.message.response &&
            response.message.response.data &&
            response.message.response.data.error &&
            response.message.response.data.error.code == 404
          ) {
            toast.error(LocaleStrings.reset_password_code_expired);
          } else {
            toast.error(LocaleStrings.something_went_wrong);
          }
        } else {
          toast.success(LocaleStrings.reset_password_success);
          this.props.history.push('/login');
          document.title = LocaleStrings.main_title;
        }
      });
    } else {
      this.setState({btnLoader: false});
      toast.error(LocaleStrings.reset_password_dont_match);
    }
  }

  render() {
    var {handleSubmit, btnLoading, language} = this.props;

    return (
      <>
        <Col lg="5" md="7">
          <Card
            className="bg-secondary shadow border-0"
            style={{boxShadow: 'none'}}>
            <CardHeader className="bg-transparent">
              <div className="text-muted text-center">
                {LocaleStrings.reset_pwd_to}
              </div>
            </CardHeader>
            <CardBody
              className=""
              style={{background: '#fff', boxShadow: 'none', border: 'none'}}>
              {this.state.showerror ? (
                <LoginErrorBar alertMessage={this.state.alertMessage} />
              ) : (
                ''
              )}

              {this.state.showsuccess ? (
                <div className="alert alert-success animated fadeIn text-center">
                  {this.state.alertMessage}
                </div>
              ) : (
                ''
              )}

              <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div
                  className="bg-primaryHover py-8 px-4 shadow sm:rounded-lg sm:px-10 "
                  style={{marginTop: '25%'}}>
                  <div className="mb-12 text-center">
                    <img
                      // className="h-6"
                      className="h-12 mx-auto mb-6"
                      // src="../../images/mainlogo.png"
                      src="../../images/kipplo-logo.webp"></img>
                    <div className="text-hint font-normal text-ternary">
                      {LocaleStrings.reset_password_reset_here}
                    </div>
                  </div>
                  <div className="space-x-4 ">
                    <form
                      className="space-y-2"
                      onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
                      <>
                        <div>
                          <div className="my-5 block">
                            <div className="mt-5">
                              <p> {LocaleStrings.email}</p>
                              <Input
                                value={
                                  this.state.params.email
                                    ? this.state.params.email
                                    : ''
                                }
                                placeholder={LocaleStrings.reset_form_ph_email}
                                type="email"
                                name="email"
                                rule={this.validateEmail}
                                readonly
                                style={{
                                  padding: '10px',
                                  width: '100%',
                                  borderRadius: '6px',
                                }}
                              />
                            </div>
                            <div className="mt-5">
                              <p> {LocaleStrings.reset_password_code}</p>
                              <Input
                                placeholder={
                                  LocaleStrings.reset_form_label_code
                                }
                                type="text"
                                readonly
                                value={
                                  this.state.params.code
                                    ? this.state.params.code
                                    : ''
                                }
                                name="code"
                                style={{
                                  padding: '10px',
                                  width: '100%',
                                  borderRadius: '6px',
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="pb-4">
                          <Field
                            name="password"
                            label={LocaleStrings.password}
                            placeholder={LocaleStrings.password}
                            type="password"
                            component={this.renderFieldText}
                            mandatory="true"
                            show={this.state.show}
                            setShow={this.changeShow}
                            labelposition={LABEL_POSITION_TOP}
                            classNameLabel="text-sm font-normal text-secondary"
                            className="cust-pr-20"
                          />
                        </div>
                        <Field
                          name="confirm_password"
                          label={
                            LocaleStrings.reset_password_field_confirm_password
                          }
                          placeholder={
                            LocaleStrings.reset_password_field_confirm_password
                          }
                          type="password"
                          component={this.renderFieldText}
                          mandatory="true"
                          show={this.state.show1}
                          setShow={this.changeShow1}
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-sm font-normal text-secondary"
                          className="cust-pr-20"
                        />
                        <div className="pt-4">
                          <button
                            type="submit"
                            disabled={this.state.isBtnDisable}
                            className={classNames(
                              this.state.isBtnDisable
                                ? 'cursor-not-allowed'
                                : '',
                              'btn-primary',
                            )}>
                            <svg
                              className={classNames(
                                this.state.btnLoader ? '' : 'sr-only',
                                'animate-spin-medium h-5 w-5 rounded-full mx-2',
                              )}
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="loading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true">
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                            {LocaleStrings.reset_password_btn_reset}
                          </button>
                        </div>
                      </>
                    </form>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Toaster />
      </>
    );
  }
}

function validate(values) {
  var errors = {};
  var password = values['password'];

  var requiredFields = ['password', 'confirm_password'];
  requiredFields.forEach(field => {
    if (!values[field] || values[field].trim() === '') {
      errors[field] = 'Required';
    }
  });

  let passwordErrorMessage =
    '<div style="width: 350px; display: table;margin-top:10px;"><div style="display: table-row"><div style=" display: table-cell;">&#8226; One lowercase character</div><div style="display: table-cell;">&#8226; Atleast one number</div></div></div><div style="width: 350px; display: table;margin-top:10px"><div style="display: table-row"><div style=" display: table-cell;">&#8226; One uppercase character</div><div style="display: table-cell;"> &#8226; 8 characters minimum</div></div></div>';
  if (password && !validatepassword(password)) {
    errors['password'] = renderHTML(passwordErrorMessage);
  }

  return errors;
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  language: state.language,
});

export default reduxForm({
  validate,
  form: 'resetPasswordForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(mapStateToProps, {
    resetStore,
    resetpassword,
  })(ResetPassword),
);
