import {
  getRequest,
  postRequest,
  deleteRequest,
  fileUploadRequest,
  patchRequest,
  deleteBulkRequest,
} from "@networkCall";

import {
  APP_API_KEY,
  INSTANCE_URL,
  itemCount,
  DEVELOPMENT_TYPE,
} from "@constant";

import LocaleStrings from "@language";

// All Exports

export var SETTINGS_SELECTED_SIDEBAR_MENU = "SETTINGS_SELECTED_SIDEBAR_MENU";
export var USERS_EXISTING_PLAN = "USERS_EXISTING_PLAN";

export function onsettingsSidebarMenuSelected(selectedMenu) {
  return (dispatch) => {
    dispatch({ type: SETTINGS_SELECTED_SIDEBAR_MENU, payload: selectedMenu });
  };
}

export function fetchUserexistingplan(session, planid, callback) {
  let filter = encodeURI(`(planid=${planid})`);
  var url = `${INSTANCE_URL}/api/v2/thomsondata/_table/subscriptionplan?filter=${filter}&related=usersubscription_by_planid`;
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({
          type: USERS_EXISTING_PLAN,
          payload: { data: data.resource },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function fetchClient(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/thomsondata/_table/client?related=*`;
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        //  dispatch({
        //    type: USERS_EXISTING_PLAN,
        //    payload: { data: data.resource},
        //  });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}
