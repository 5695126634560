import React from 'react';
import {connect} from 'react-redux';
import LocaleStrings from '@language';
import _ from 'lodash';
import toast, {Toaster} from 'react-hot-toast';
import {Fragment} from 'react';
import {Transition, Dialog} from '@headlessui/react';
import {StripeProvider, Elements} from 'react-stripe-elements';
import {BaseComponent} from '@baseComponent';
import {
  addpaymentModal,
  selectedaddOn,
  payforAddon,
  addoncredits_email,
} from '@creditAddonActions';
import {STRIPE_PUBLISHABLE_KEY} from '@constant';
import AddPayfastForm from './stripepaymentform';

class AddPaymentmodal extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isBtnDisable: false,
      btnLoader: false,
      buttonLoader: false,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillUnmount() {}

  closeModal() {
    this.props.selectedaddOn({});
    this.props.addpaymentModal(false);
  }

  tokenReceived = (token, error) => {
    let {user, session, selectedaddOnData, appuserData} = this.props;

    let obj = {};
    let emailobj = {};
    let transactionid = '';

    // if (
    //   appuserData &&
    //   appuserData.data &&
    //   appuserData.data.length > 0 &&
    //   appuserData.data[0].client_by_clientid
    // ) {
    //   let client_by_clientid = appuserData.data[0]?.client_by_clientid;
    //   if (
    //     appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid &&
    //     appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid
    //       .length > 0
    //   ) {
    //     let usersubscription_by_clientid =
    //       appuserData.data[0]?.client_by_clientid
    //         ?.usersubscription_by_clientid[0];

    //     transactionid = usersubscription_by_clientid.transactionid;
    //   }
    // }
    // emailobj.transactionid = transactionid;
    if (token && token.card && token.card.id) {
      obj.clientid = user.clientid;
      obj.tokenid = token.id;
      obj.planid = selectedaddOnData.planid;
      this.props.payforAddon(session, obj, callback => {
        if (callback.success == 0) {
          toast.error(LocaleStrings.addons_payment_paymentfailed);
        } else {
          toast.success(LocaleStrings.addons_payment_paymentsuccess);
          this.props.history.push('/dashboard/main/dashboardmain');
          this.closeModal();
          transactionid = callback.result.transactionid;
          emailobj.transactionid = transactionid;
          if (emailobj.transactionid != '') {
            this.props.addoncredits_email(session, emailobj);
          }
        }
      });
    } else {
    }
  };

  render() {
    let {selectedaddOnData} = this.props;

    return (
      <>
        <Transition.Root show={this.props.isaddpaymentmodalOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.isaddpaymentmodalOpen}
            onClose={this.closeModal}
            initialFocus={this.myRef}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block rounded-xl align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-11/12 "
                  style={{padding: 0}}>
                  <div className="p-6 border-t-2 border-quaternary">
                    <div className="flex py-4">
                      <div className="pr-4 pt-2">
                        {LocaleStrings.addons_payment_via}
                      </div>
                      <img src="../../images/billing/stripeicon.png" />
                    </div>
                    <div className="pb-3 text-lg mainGray">
                      {LocaleStrings.addons_payment_selectedaddon}{' '}
                      {selectedaddOnData?.name}
                    </div>
                    <div className="pb-6 text-sm text-gray500 font-normal">
                      {LocaleStrings.addons_payment_amountpayable}{' '}
                      {LocaleStrings.dollar}
                      {selectedaddOnData?.price}
                    </div>
                    <StripeProvider apiKey={STRIPE_PUBLISHABLE_KEY}>
                      <Elements>
                        <AddPayfastForm
                          {...this.props}
                          onTokenReceived={this.tokenReceived}
                          onCloseModal={this.closeModal}
                          values={selectedaddOnData}
                        />
                      </Elements>
                    </StripeProvider>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function validate(values) {
  var errors = {};

  return errors;
}

function mapStateToProps(state, ownProps) {
  var {session, user} = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    isaddpaymentmodalOpen: state.isaddpaymentmodalOpen,
    selectedaddOnData: state.selectedaddOnData,
    appuserData: state.appuserData,
  };
}

export default connect(mapStateToProps, {
  addpaymentModal,
  selectedaddOn,
  payforAddon,
  addoncredits_email,
})(AddPaymentmodal);
