import axios from "axios";
import { APP_API_KEY, INSTANCE_URL, LOGIN } from "@constant";
import {
  postRequest,
  patchRequest,
  postRequestAccess,
  getRequest,
  postRequestnew,
} from "@networkCall";
// import { getHeaders } from "../common/common-utils.js";
import LocaleStrings from "@language";

export var SESSION_TOKEN_PASSWORD = "SESSION_TOKEN_PASSWORD";

// changePassword
export function changePassword(session, values, callback) {
  return (dispatch) => {
    var url = `${INSTANCE_URL}/api/v2/user/password?reset=true&login=true`;
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        // console.log('response',response);
        var returnData = { error: 0, data: "Password changed successfully!" };
        dispatch({
          type: SESSION_TOKEN_PASSWORD,
          payload: { session_token: response?.session_token },
        });
        callback(returnData);
      },
      (error) => {
        var returnData = { error: 1, data: error.message };
        callback(returnData);
      }
    );
  };
}
