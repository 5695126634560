import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import toast, {Toaster} from 'react-hot-toast';
import LocaleStrings from '@language';
import {BaseComponent, LABEL_POSITION_TOP} from '@baseComponent';
import {classNames} from '@commonFunction';
import {
  mainLogin,
  resetStore,
  isForgotPasswordOpen,
  validateSocialId,
  socialSignupAvailable,
  logout,
} from '@mainActions';
import _ from 'lodash';
import SocialLogin from '../sociallogin/index';
import {logGTMEvent} from '@commonFolder/gtm-event';

class Login extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      btnLoading: false,
      showerror: false,
      show: false,
      fbloader: false,
      signup: false,
      btnLoader: false,
      isBtnDisable: false,
    };

    // All Bind Functions
    this.forGotPassword_click = this.forGotPassword_click.bind(this);
    this.changeShow = this.changeShow.bind(this);
  }

  UNSAFE_componentWillMount() {
    let {session, user} = this.props;
    // console.log("isLoggedIn", this.props.isLoggedIn);
    if (this.props.isLoggedIn === true) {
      // this.props.history.push("/dashboard/main/dashboardmain");
    } else {
      // this.props.history.push("/login");
    }
  }

  changeShow() {
    let {show} = this.state;
    this.setState({show: !show});
  }

  // Forgot Password Button Click
  forGotPassword_click() {
    this.props.history.push('/forgotpassword');
  }

  onFormSubmit(values) {
    let {session, user} = this.props;
    this.setState({btnLoader: true});
    this.setState({isBtnDisable: true});
    values.comesFrom = 'loginpage';

    if (values.hasOwnProperty('email')) {
      values.email = values.email.toLowerCase();
    }

    this.props.mainLogin(values, this.props.session, response => {
      if (response.success === 0) {
        toast.error(LocaleStrings.invalid_email_phone_password);
        this.setState({btnLoader: false});
        this.setState({isBtnDisable: false});
        // this.props.resetStore();
      } else if (response.success === 2) {
        // this.props.logout(this.props.session);
        toast.error(LocaleStrings.unverified_user_error);
        this.setState({isBtnDisable: false});
        this.setState({btnLoader: false});
        this.props.history.push('/verifyemail');
      } else if (response.success === 3) {
        this.props.logout(this.props.session);
        toast.error(LocaleStrings.removed_by_admin_error);
        this.setState({isBtnDisable: false});
        this.setState({btnLoader: false});
      } else if (response.success === 4) {
        this.props.logout(this.props.session);
        toast.error(LocaleStrings.disabled_by_admin_error);
        this.setState({isBtnDisable: false});
        this.setState({btnLoader: false});
      } else if (response.success === 5) {
        this.props.logout(this.props.session);
        toast.error(LocaleStrings.invitationaccept_by_admin_error);
        this.setState({isBtnDisable: false});
        this.setState({btnLoader: false});
      } else {
        this.setState({isBtnDisable: false});
        this.setState({btnLoader: false});
        toast.success(LocaleStrings.login_success);
        this.props.history.push('/dashboard/main/dashboardmain');

        logGTMEvent({
          event: 'platformSignIn',
          method: 'email',
        });
      }
    });
  }

  loginsignupToggle = () => {
    this.props.history.push('/register');
  };

  componentWillReceiveProps(nextProps) {
    // if (nextProps.showModal) {
    //   this.setState({ ...this.state, loading: false });
    // }
  }

  render() {
    var {handleSubmit} = this.props;
    var {signup} = this.state;

    return (
      <>
        <div className="main-login">
          <div className="grid xs:grid-cols-1 sm:grid-cols-2">
            <div className="xs:mt-16 xs:mx-8 lg:mt-48 xl:mx-48">
              <img
                // className="h-6"
                className="w-40"
                // className="h-12 w-52"
                // src="../../images/mainlogo.png"
                src="../../images/kipplo-logo.webp"></img>
              <form
                className="space-y-2"
                onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
                <div className="mb-8">
                  <div className="text-4xl mt-10 font-medium">
                    {LocaleStrings.mainlogin_title}
                  </div>
                  <div className="mt-2 font-normal text-ternary">
                    {LocaleStrings.mainlogin_subtitle}
                  </div>
                </div>
                <div className="pb-4">
                  <Field
                    name="email"
                    label="Business Email"
                    placeholder="Enter your business email"
                    type="text"
                    component={this.renderFieldText}
                    mandatory="true"
                    labelposition={LABEL_POSITION_TOP}
                    classNameLabel="text-sm font-normal text-secondary"
                  />
                </div>
                <Field
                  name="password"
                  label={LocaleStrings.password}
                  placeholder={LocaleStrings.password}
                  type="password"
                  component={this.renderFieldText}
                  mandatory="true"
                  show={this.state.show}
                  setShow={this.changeShow}
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-sm font-normal text-secondary"
                  className="cust-pr-20"
                />
                <div className="flex items-center justify-between pt-6">
                  <div className="text-sm">
                    <a
                      onClick={this.forGotPassword_click}
                      className="font-medium text-primary cursor-pointer">
                      {LocaleStrings.forgot_password}
                    </a>
                  </div>
                </div>
                <div className="pt-4">
                  <button
                    type="submit"
                    disabled={this.state.isBtnDisable}
                    className={classNames(
                      this.state.isBtnDisable ? 'cursor-not-allowed' : '',
                      'btn-primary',
                    )}>
                    <svg
                      className={classNames(
                        this.state.btnLoader ? '' : 'sr-only',
                        'animate-spin-medium h-5 w-5 rounded-full mx-2',
                      )}
                      viewBox="0 0 1024 1024"
                      focusable="false"
                      data-icon="loading"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true">
                      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                    </svg>
                    {LocaleStrings.signin}
                  </button>
                </div>
                {/* <SocialLogin comesFrom="login" history={this.props.history} /> */}
                {/* <div className="pt-4">
                  <button type="button" className="btn-white">
                    <img className="pr-2" src="../../images/google.png"></img> {LocaleStrings.mainlogin_signingoogle}
                  </button>
                </div>
                <div className="pt-4">
                  <button type="button" className="btn-white">
                    <img className="pr-2" src="../../images/linkedin.png"></img> {LocaleStrings.mainlogin_signinlinkedin}
                  </button>
                </div> */}
                <div className="pt-4 justify-center flex">
                  <div className="mt-2 font-normal text-ternary">
                    {LocaleStrings.mainlogin_donthaveacc}
                  </div>
                  <div
                    className="mt-2 ml-1 font-normal text-primary cursor-pointer"
                    onClick={this.loginsignupToggle}>
                    {LocaleStrings.signup}
                  </div>
                </div>
              </form>
            </div>
            <img
              className="object-cover w-full"
              src="../../images/Section.png"
              style={{height: '100vh'}}></img>
          </div>
        </div>

        <Toaster limit={1} />
      </>
    );
  }
}

function validate(values) {
  var errors = {};

  if (!values.email) {
    errors.email = LocaleStrings.login_form_validation_email_required;
  }

  if (!values.password) {
    errors.password = LocaleStrings.login_form_validation_password_required;
  }
  return errors;
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  showModal: state.openLoginOTPModal,
  isforgotfasswordopen: state.isForgotPasswordOpen,
});

export default reduxForm({
  validate,
  form: 'LoginForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(mapStateToProps, {
    mainLogin,
    resetStore,
    isForgotPasswordOpen,
    validateSocialId,
    socialSignupAvailable,
    logout,
  })(Login),
);
