import React, {Component} from 'react';
import {connect} from 'react-redux';
import blankImg from '../../assets/images/error_placeholder.svg';
import LocaleStrings from '@language';

class PayFail extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    // All Binded Functions
  }

  handlePlanPage() {
    this.props.history.push('/dashboard/upgradeplan');
  }
  render() {
    return (
      <>
        <main className="scrollbar-hide xs:px-6 sm:px-12 lg:px-12 py-10 overflow-y-auto bg-homegray">
          <div className="flex items-center justify-center h-5/6">
            <div className="text-center">
              <img className="inline mb-3" src={blankImg} />
              <h2 className="text-2xl font-normal text-primary">
                {LocaleStrings.pay_fail_title1}
              </h2>
              <div className=" grid grid-cols-1 md:grid-cols-3 ">
                <div></div>
                <div>
                  <button
                    className="btn-primary mt-6 w-64"
                    onClick={() => this.handlePlanPage()}>
                    {LocaleStrings.pay_btn_gotoplan}
                  </button>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

function mapStateToProps(state) {
  var {session, user} = state;
  return {
    session,
    user,
    subscriptionplanList: state.subscriptionplanList,
    appuserData: state.appuserData,
  };
}
export default connect(mapStateToProps, {})(PayFail);
