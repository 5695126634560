import {
  getRequest,
  postRequest,
  deleteRequest,
  fileUploadRequest,
  patchRequest,
  deleteBulkRequest,
} from '@networkCall';

import {
  APP_API_KEY,
  INSTANCE_URL,
  itemCount,
  DEVELOPMENT_TYPE,
} from '@constant';

import LocaleStrings from '@language';

// All Exports

export var SELECTED_SIDEBAR_MENU = 'SELECTED_SIDEBAR_MENU';
export var INVITED_APPUSERDETAILS = 'INVITED_APPUSERDETAILS';
export var APPUSERDETAILS = 'APPUSERDETAILS';
export var NOTIFICATION_COUNT = 'NOTIFICATION_COUNT'; // STORE NOTIFICATION COUNT

export const IS_NOTIFICATION_SLIDEOVERS_MODAL_OPEN =
  'IS_NOTIFICATION_SLIDEOVERS_MODAL_OPEN'; // ON NAV BAR (NOTIFICATION ICON)
export const NOTIFICATION_DETAILS_LIST = 'NOTIFICATION_DETAILS_LIST'; // NOTIFICATION DETAILS LIST (NOTIFICATION ICON CLICK)
export const MESSAGING_DETAILS_LIST = 'MESSAGING_DETAILS_LIST'; // MESSAGE DETAILS LIST (NAV BAR ICON)
export const IS_MESSAGING_SLIDEOVERS_OPEN = 'IS_MESSAGING_SLIDEOVERS_OPEN'; // ON NAV BAR (MESSAGE ICON)
export const THREAD_MESSAGE = 'THREAD_MESSAGE'; // Messages with Thread ID
export const SINGLE_NOTIFICATION_OBJECT = 'SINGLE_NOTIFICATION_OBJECT'; // NOTIFICATION SELECTED OBJECT {SINGEL}
export const NOTIFICATION_DETAILS = 'NOTIFICATION_DETAILS'; // NOTIFICATION DETAILS [BY ID]

export const SELECTED_MESSAGE_TAB = 'SELECTED_MESSAGE_TAB'; // COMMON MASSAGE for products and order rated message
export const ORDER_MESSAGING_DETAILS_LIST = 'ORDER_MESSAGING_DETAILS_LIST';
export const ORDER_MESSAGING_STEP = 'ORDER_MESSAGING_STEP'; // This time putting step into redux for order messages thread
export const ORDER_THREAD_MESSAGES = 'ORDER_THREAD_MESSAGES'; // Order Messages with Thread ID
export const ORDER_THREAD_BASICDATA = 'ORDER_THREAD_BASICDATA'; // SOME BASIC DATA WE WILL USE IN ORDER MESSAGE SCREEN

export function fetchAppuser(session, appuserid, callback) {
  var filter = encodeURI(`filter=(appuserid=${appuserid})`);
  var url = `${INSTANCE_URL}/api/v2/thomsondata/_table/appuser?${filter}`;
  return dispatch => {
    getRequest(
      url,
      session,
      dispatch,
      data => {
        const appuserData = {data: data.resource};
        let businesscredits = 0;
        let personalcredits = 0;
        let contactcredits = 0;

        let planid = '';
        let sku = '';
        let role = '';

        let subscriptionSKU = '';

        if (
          appuserData &&
          appuserData.data &&
          appuserData.data.length > 0 &&
          appuserData.data[0].client_by_clientid
        ) {
          role = appuserData.data[0].role;

          if (
            appuserData.data[0]?.client_by_clientid
              ?.usersubscription_by_clientid &&
            appuserData.data[0]?.client_by_clientid
              ?.usersubscription_by_clientid.length > 0 &&
            appuserData.data[0]?.client_by_clientid
              ?.usersubscription_by_clientid[0]?.subscriptionplan_by_planid
          ) {
            let subscriptionplan_by_planid =
              appuserData.data[0]?.client_by_clientid
                ?.usersubscription_by_clientid[0]?.subscriptionplan_by_planid;
            planid = subscriptionplan_by_planid.planid;
            subscriptionSKU = subscriptionplan_by_planid.sku;
          }

          if (
            appuserData &&
            appuserData.data &&
            appuserData.data.length > 0 &&
            appuserData.data[0].client_by_clientid
          ) {
            if (
              appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid &&
              appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid
                .length > 0
            ) {
              let usercredit_by_clientid =
                appuserData.data[0]?.client_by_clientid
                  ?.usercredit_by_clientid[0];

              contactcredits = usercredit_by_clientid.contactcredit;
              businesscredits = usercredit_by_clientid.bus_email_credits;
              personalcredits = usercredit_by_clientid.personal_email_credits;
            }
          }
        }

        const credits = {
          contactcredits,
          personalcredits,
          businesscredits,
          role,
          planid,
          subscriptionSKU,
        };

        dispatch({
          type: APPUSERDETAILS,
          payload: {data: data.resource, credits},
        });
        callback({success: 1, data: data});
      },
      error => {},
    );
  };
}

/* Update SOME BASIC DATA */
export function updateBasicOrderthreadData(data) {
  return dispatch => {
    dispatch({type: ORDER_THREAD_BASICDATA, payload: data});
  };
}

/* Update current step for order message thread [1 -> all thread list 2 means messgae list]*/
export function updateOrderStep(step) {
  return dispatch => {
    dispatch({type: ORDER_MESSAGING_STEP, payload: step});
  };
}

/* Update the current tab name for massage section */
export function changeMessageTabName(selectedTab) {
  return dispatch => {
    dispatch({type: SELECTED_MESSAGE_TAB, payload: selectedTab});
  };
}

export function onSidebarMenuSelected(selectedMenu) {
  return dispatch => {
    dispatch({type: SELECTED_SIDEBAR_MENU, payload: selectedMenu});
  };
}

// NOTIFICATON
/***** Notification Slide-overs Modal open or not *****/
export function isNotificationModalOpen(isOpen) {
  return {type: IS_NOTIFICATION_SLIDEOVERS_MODAL_OPEN, payload: isOpen};
}

/**** Fetch Notification Count ****/
export function showNotificatonCount(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/datascript/appcounters`;
  let body = {};

  // Actual Data Call
  return dispatch => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      response => {
        dispatch({
          type: NOTIFICATION_COUNT,
          payload: {data: response.data},
        });

        callback({status: 1, message: LocaleStrings.api_success_status});
      },
      error => {
        callback({status: 0, message: LocaleStrings.something_went_wrong});
      },
    );
  };
}

/**** FEtch Notification Details ****/
export function fetchNotificationDetails(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/shopez/_table/user_notifications?related=notifications_by_notificationid&fields=*&filter=userid=${session.userid}&order=id%20DESC`;
  let body = {};

  // Actual Data Call
  return dispatch => {
    getRequest(
      url,
      session,
      dispatch,
      response => {
        dispatch({
          type: NOTIFICATION_DETAILS_LIST,
          payload: {data: response.resource},
        });

        callback({status: 1, message: LocaleStrings.api_success_status});
      },
      error => {
        callback({status: 0, message: LocaleStrings.something_went_wrong});
      },
    );
  };
}

/**** Mark all Read Notification ****/
export function markallread(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/datascript/markallread`;
  let body = {};

  // Actual Data Call
  return dispatch => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      response => {
        callback({status: 1, message: LocaleStrings.api_success_status});
      },
      error => {
        callback({status: 0, message: LocaleStrings.something_went_wrong});
      },
    );
  };
}

/*** Mark as read with notification ID ***/
export function markRead(session, notificationId, callback) {
  var url = `${INSTANCE_URL}/api/v2/notificationscript/patch`;
  let body = {
    notificationid: notificationId,
  };

  // Actual Data Call
  return dispatch => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      response => {
        callback({status: 1, message: LocaleStrings.api_success_status});
      },
      error => {
        callback({status: 0, message: LocaleStrings.something_went_wrong});
      },
    );
  };
}

/*** Set Notification SIngle Object ***/
export function NotificationSingleObject(data) {
  return {type: SINGLE_NOTIFICATION_OBJECT, payload: data};
}

/*** Set Notification Details By Notification ID ***/
export function NotificationDeatils(session, notificationId, callback) {
  // return { type: NOTIFICATION_DETAILS, payload: data };
  var url = `${INSTANCE_URL}/api/v2/notificationscript/notificationdetailsbyid`;
  let body = {
    notificationid: notificationId,
  };

  // Actual Data Call
  return dispatch => {
    postRequest(
      url,
      body,
      session,
      dispatch,
      response => {
        dispatch({
          type: NOTIFICATION_DETAILS,
          payload: {data: response.resource},
        });

        callback({status: 1, message: LocaleStrings.api_success_status});
      },
      error => {
        callback({status: 0, message: LocaleStrings.something_went_wrong});
      },
    );
  };
}

/*** Make Notification Details Empty [After Any modal close] */
export function makeNotificationDetailsEmpty() {
  return {type: NOTIFICATION_DETAILS, payload: {}};
}

export function fetchinvitedappuser(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/getappuserbyemail`;
  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      response => {
        // console.log('res',response)
        dispatch({
          type: INVITED_APPUSERDETAILS,
          payload: {data: response.resource},
        });
        callback({success: 1, result: response});
      },
      error => {
        // console.log(error)
        callback({success: 0});
      },
    );
  };
}

export function updatinvitedappuser(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/updatinvitedappuser`;
  return dispatch => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      response => {
        // console.log('res',response)
        callback({success: 1, result: response});
      },
      error => {
        // console.log(error)
        callback({success: 0, message: error});
      },
    );
  };
}
