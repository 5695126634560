import {
  ALL_REVEALEDCOUNT,
  ALL_REVEALEDCOUNT_DIRECTDIALS,
} from '@maindashBoardActions';

// export var revealcontactDatalist = (state = {}, action) => {
//   switch (action.type) {
//     case ALL_REVEALEDCOUNT:
//       return action.payload;
//       break;
//     default:
//       return state;
//       break;
//   }
// };

export var revealcontactDirectDials = (state = {}, action) => {
  switch (action.type) {
    case ALL_REVEALEDCOUNT_DIRECTDIALS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

export var revealedcontacts = (state = {}, action) => {
  switch (action.type) {
    case ALL_REVEALEDCOUNT:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
