import {UPLOAD_FILES_API_KEY, APP_API_KEY} from '@constant';

export var getHeaders = session => ({
  headers: {
    'X-ApiFlo-Api-Key': session.apiKey,
    ...(session.sessionToken && session.sessionToken !== ''
      ? {'X-ApiFlo-Session-Token': session.sessionToken}
      : {}),
    contentType: 'application/json; charset=utf-8',
  },
});

export var getHeadersWOSession = session => ({
  headers: {
    'X-ApiFlo-Api-Key': APP_API_KEY,
    contentType: 'application/json; charset=utf-8',
  },
});

export var getFlatHeaders = session => ({
  'X-ApiFlo-Api-Key': session.apiKey,
  'X-ApiFlo-Session-Token': session.sessionToken,
  contentType: 'application/json; charset=utf-8',
});

export var getMultiPartHeaders = session => ({
  headers: {
    'X-ApiFlo-Api-Key': session.apiKey,
    'X-ApiFlo-Session-Token': session.sessionToken,
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

export var getHeadersMultiPart = session => ({
  headers: {
    'X-ApiFlo-Api-Key': session.apiKey,
    'X-ApiFlo-Session-Token': session.sessionToken,
    'Content-Type':
      'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
  },
});

export var getHeadersWithParams = (session, params) => ({
  headers: {
    'X-ApiFlo-Api-Key': session.apiKey,
    'X-ApiFlo-Session-Token': session.sessionToken,
    'Content-Type': 'multipart/form-data',
  },
  params: params,
});

export var getImageHeaders = session => ({
  headers: {
    'X-ApiFlo-Api-Key': UPLOAD_FILES_API_KEY,
    'X-ApiFlo-Session-Token': session.sessionToken,
    contentType: 'application/json; charset=utf-8',
  },
});

export function decryptAES256(incomingData, key) {
  // function pkcs7_unpad(data) {
  //   const paddingSize = ord(data[strlen(data) - 1]);
  //   if (paddingSize > strlen(data)) {
  //     throw new Exception('Invalid padding');
  //   }
  //   return substr(data, 0, -1 * paddingSize);
  // }
  // const data = base64_decode(incomingData);
  // const ivLength = openssl_cipher_iv_length('aes-256-cbc');
  // const iv = substr(data, 0, ivLength);
  // let encryptedData = substr(data, ivLength);
  // let decryptedData = openssl_decrypt(encryptedData, 'aes-256-cbc', key, 0, iv);
  // return pkcs7_unpad(decryptedData);
}

export function parse(incomingData, key) {
  let result = '';

  try {
    const data = atob(incomingData);

    let keyLength = key.length;

    for (let i = 0; i < data.length; i++) {
      let char = data.charAt(i);
      let keyChar = key.charAt(i % keyLength);

      result += String.fromCharCode(char.charCodeAt(0) ^ keyChar.charCodeAt(0));
    }
  } catch (error) {}

  return result;
}

export function normalizeLinkedinUrl(url) {
  let linkedinurl = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '');
  if (linkedinurl.indexOf('?miniProfileUrn=') > -1) return;
  if (linkedinurl.indexOf('#') > -1) linkedinurl = linkedinurl.split('#')[0];
  linkedinurl = linkedinurl.replace(/\/$/, '');
  return linkedinurl;
}

export const appConstant = {
  kFetchedPersonalEmail: false,
  kFetchedBusinessEmail: false,
  kFetchedPhone: false,
};
