import { ALL_SUBSCRIPTION_PLANS_SETTINGS } from "@settingsPlansActions";

export var subscriptionplanListsettings = (state = {}, action) => {
  switch (action.type) {
    case ALL_SUBSCRIPTION_PLANS_SETTINGS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
