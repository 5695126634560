
  import LocaleStrings from "@language";
  import {SETTINGS_SELECTED_SIDEBAR_MENU,USERS_EXISTING_PLAN} from "@mainSettingsActions";

  export var SettingsSideBarMenus = (state = [], action) => {
    //iconName is optional
    var state = [
      {
        name: LocaleStrings.setting_settingsSideBarMenus1,
        href: "myaccount",
        icon: "myaccount",
        current: true,
        position: 1,
      },
      {
        name: LocaleStrings.setting_settingsSideBarMenus2,
        href: "password",
        icon: "password",
        current: false,
        position: 2,
      },
      {
        name: LocaleStrings.setting_settingsSideBarMenus3,
        href: "manageseats",
        icon: "manageseats",
        current: false,
        position: 3,
      },
      {
        name: LocaleStrings.setting_settingsSideBarMenus4,
        href: "billing",
        icon: "billing",
        current: false,
        position: 4,
      },
      {
        name: LocaleStrings.setting_settingsSideBarMenu5,
        href: "plans",
        icon: "plans",
        current: false,
        position: 5,
      },
      {
        name: LocaleStrings.setting_settingsSideBarMenus6,
        href: "usage",
        icon: "usage",
        current: false,
        position: 6,
      },
      {
        name: LocaleStrings.setting_settingsSideBarMenus7,
        href: "api",
        icon: "api",
        current: false,
        position: 7,
      },
      {
        name: LocaleStrings.setting_settingsSideBarMenus8,
        href: "privacy",
        icon: "privacy",
        current: false,
        position: 8,
      },
      
    ];
  
    return state;
  };
  

  // Initial select tab is "SETTINGS_SELECTED_SIDEBAR_MENU"
export var SettingsidebarSelectedMenu = (state = 1, action) => {
    if (action.type === SETTINGS_SELECTED_SIDEBAR_MENU) {
      state = action.payload;
    }
    return state;
  };
  

  export var userexistingplanData = (state = {}, action) => {
    switch (action.type) {
        case USERS_EXISTING_PLAN:
            return action.payload;
            break;
        default:
            return state;
            break;
    }
}
