import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import LocaleStrings from "@language";
import { Field, reduxForm, getFormValues } from "redux-form";
import { BaseComponent, LABEL_POSITION_TOP } from "@baseComponent";
import { classNames } from "@commonFunction";
import { sendEmailfordemo } from "@scheduledemoActions";
import toast, { Toaster } from "react-hot-toast";
import { validateEmail } from "@constant";

class ScheduleDemo extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: false,
      phone: "",
      country: "",
      isBtnDisable: false,
      btnLoader: false,
    };
    // All Binded Functions
  }

  componentDidMount() {
    let { session, user } = this.props;
  }

  // _phoneChange = (value, country, e, formattedValue) => {
  //     this.setState({ phone: value, country: country.dialCode });
  // };

  onFormSubmit(values) {
    let { session, user } = this.props;
    if (values.name) {
      values.name = _.trim(values.name);
    }
    if (values.email) {
      values.email = _.trim(values.email);
    }
    if (values.query) {
      values.query = _.trim(values.query);
    }
    if (
      values &&
      values.name &&
      values.email &&
      values.query &&
      values.name !== "" &&
      values.email !== "" &&
      values.query !== ""
    ) {
      this.setState({ isBtnDisable: true });
      this.setState({ btnLoader: true });
      this.props.sendEmailfordemo(session, values, (callback) => {
        if (callback && callback.success == 1) {
          toast.success(LocaleStrings.schedule_demo_success_message);
          this.setState({ isBtnDisable: false });
          this.setState({ btnLoader: false });
          this.props.reset();
        } else {
          toast.error(LocaleStrings.common_fail_message);
          this.setState({ isBtnDisable: false });
          this.setState({ btnLoader: false });
        }
      });
    } else {
      toast.error(LocaleStrings.common_error_fillallrequiredfields);
    }
  }

  render() {
    let { handleSubmit } = this.props;
    return (
      <>
        <form
          className="space-y-2  xs:px-20 lg:px-0"
          onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
          <div className="grid xs:grid-cols-1 lg:grid-cols-3 xs:mt-10 lg:mt-28">
            <div></div>
            <div className="lg:w-5/6  lg:ml-auto">
              <div className="text-4xl font-bold">
                {LocaleStrings.schedule_demo_header}
              </div>
              <div className="mt-4 text-gray500 text-base">
                {LocaleStrings.schedule_demo_description}
              </div>
              <div className="mt-4">
                <Field
                  name="name"
                  label="Name"
                  placeholder="Name"
                  type="text"
                  mandatory="true"
                  component={this.renderFieldText}
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-sm font-normal text-secondary"
                />
              </div>
              <div className="mt-4">
                <Field
                  name="email"
                  label="Email"
                  placeholder="Email"
                  type="text"
                  mandatory="true"
                  component={this.renderFieldText}
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-sm font-normal text-secondary"
                />
              </div>
              {/* <div className="mt-4">
                                <Field
                                    name="phone"
                                    label={LocaleStrings.settings_myaacounts_field_phone}
                                    type="text"
                                    mandatory="true"
                                    component={this.renderPhonewithFlag}
                                    className="cust-input-field"
                                    classNameLabel="text-box-lable"
                                    phone={this.state.phone}
                                    phoneChange={this._phoneChange}
                                />
                            </div> */}
              <div className="mt-4">
                <Field
                  name="query"
                  label="Query"
                  placeholder="Query"
                  component={this.renderFieldTextarea}
                  maxlength={200}
                  mandatory="true"
                  labelposition={LABEL_POSITION_TOP}
                  classNameLabel="text-box-lable"
                  className="py-3 px-4 block w-full shadow-sm text-secondary focus:ring-primary focus:border-teal-500 border border-warm-gray-300 rounded-md"
                />
              </div>
              <div className="pt-6 flex space-x-4">
                <button
                  type="submit"
                  disabled={this.state.isBtnDisable}
                  className={classNames(
                    this.state.isBtnDisable ? "cursor-not-allowed" : "",
                    "btn-primary"
                  )}>
                  <svg
                    className={classNames(
                      this.state.btnLoader ? "" : "sr-only",
                      "animate-spin-medium h-5 w-5 rounded-full mx-2"
                    )}
                    viewBox="0 0 1024 1024"
                    focusable="false"
                    data-icon="loading"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true">
                    <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                  </svg>
                  {LocaleStrings.schedule_demo_btn}
                </button>
              </div>
            </div>
          </div>
        </form>
        <Toaster />
      </>
    );
  }
}

function validate(values) {
  var errors = {};
  var requiredFields = [];
  var email = values["email"];
  requiredFields = ["name", "email", "query"];
  if (email && !validateEmail(email)) {
    errors["email"] = "Invalid email";
  }

  requiredFields.forEach((field) => {
    if (!values[field] || values[field] === "") {
      errors[field] = LocaleStrings.required;
    }
  });

  return errors;
}

function mapStateToProps(state) {
  var { session, user } = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
  };
}

export default reduxForm({
  validate,
  form: "ScheduleDemoForm",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(
  connect(mapStateToProps, {
    sendEmailfordemo,
  })(ScheduleDemo)
);
