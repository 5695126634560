import { ALL_MYCONTACTS } from "@myContactsActions";

export var mycontactsDataList = (state = {}, action) => {
  switch (action.type) {
    case ALL_MYCONTACTS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
