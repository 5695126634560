import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import { Field, reduxForm } from "redux-form";
import { BaseComponent, LABEL_POSITION_TOP } from "@baseComponent";
import toast, { Toaster } from "react-hot-toast";
// Functions Import
import { classNames } from "@commonFunction";
// Import constant
import { validateEmail } from "@constant";
import { sendresetpasswordLink, resetStore } from "@mainActions";

class ForgotPassword extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      headerText: "",
      btnLoader: false,
      isBtnDisable: false,
    };

    this.closeModal = this.closeModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({ open: true });
    }
    if (nextProps.hedaer) {
      this.setState({ headerText: nextProps.hedaer });
    }
  }

  closeModal() {
    this.setState({ open: false });
    this.props.isForgotPasswordOpen(false);
  }

  /* Form Submit */
  onFormSubmit(values) {
    this.setState({ btnLoader: true });
    this.setState({ isBtnDisable: true });

    this.props.sendresetpasswordLink(values, (response) => {
      if (response.success === 0) {
        toast.error(LocaleStrings.invalid_email_phone_password);
        this.setState({ btnLoader: false });
        this.setState({ isBtnDisable: false });
        this.props.resetStore();
      } else {
        this.setState({ isBtnDisable: false });
        this.setState({ btnLoader: false });
        toast.success(LocaleStrings.forgot_password_success_message);
        // this.props.history.push("/dashboard/main/dashboardmain");
      }
    });
  }

  _backtoLogin = () => {
    this.props.history.push("/login");
  };
  render() {
    var { handleSubmit } = this.props;
    return (
      <>
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <form
              className="space-y-2"
              onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
              <div className="center-item">
                <img className="" src="../../images/forgotpassword.png"></img>
              </div>
              <div className="center-item text-3xl font-medium pt-4">
                {LocaleStrings.forgot_password}
              </div>
              <div className="center-item">
                <div className="mt-2 font-normal text-ternary">
                  {LocaleStrings.forgot_password_subtitle}
                </div>
              </div>
              <div className="center-item">
                <div className="py-4 w-9/12">
                  <Field
                    name="email"
                    label={LocaleStrings.email}
                    placeholder={LocaleStrings.mainsignup_email_placeholder}
                    type="text"
                    component={this.renderFieldText}
                    mandatory="true"
                    labelposition={LABEL_POSITION_TOP}
                    classNameLabel="text-sm font-normal text-secondary"
                  />
                </div>
              </div>
              <div className="center-item">
                <div className="w-9/12">
                  <button
                    type="submit"
                    disabled={this.state.isBtnDisable}
                    className={classNames(
                      this.state.isBtnDisable ? "cursor-not-allowed" : "",
                      "btn-primary"
                    )}>
                    <svg
                      className={classNames(
                        this.state.btnLoader ? "" : "sr-only",
                        "animate-spin-medium h-5 w-5 rounded-full mx-2"
                      )}
                      viewBox="0 0 1024 1024"
                      focusable="false"
                      data-icon="loading"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true">
                      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                    </svg>
                    {LocaleStrings.reset_password}
                  </button>
                </div>
              </div>
              <div
                className="center-item font-normal text-ternary flex pt-4 cursor-pointer"
                onClick={this._backtoLogin}>
                <img
                  className="pl-2 h-5 pt-1"
                  src="../../images/backicon.png"
                />
                <div className="pl-3">{LocaleStrings.backto_login}</div>
              </div>
            </form>
          </div>
        </div>
        <Toaster />
      </>
    );
  }
}

function validate(values) {
  var errors = {};
  var requiredFields = ["email"];
  var email = values["email"];

  if (!values.email) {
    errors.email = LocaleStrings.login_form_validation_email_required;
  }

  if (email && !validateEmail(email)) {
    errors["email"] = LocaleStrings.invalid_email;
  }

  return errors;
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
});

export default reduxForm({
  validate,
  form: "ForgotPasswordForm",
})(
  connect(mapStateToProps, {
    sendresetpasswordLink,
    resetStore,
  })(ForgotPassword)
);
