import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    // All Binded Functions
  }

  componentDidMount() {
    let { session, user } = this.props;
  }

  _handelback = () => {
    this.props.history.push("/dashboard/settings/privacy");
  };

  render() {
    return (
      <>
        <div className="h-screen flex overflow-hidden bg-quaternary">
          <div className="flex flex-col w-0 flex-1 overflow-hidden">
            <div className="flex-shrink-0 flex h-16 bg-custWhite shadow border-b border-gray-200">
              <div className="flex-1 px-4 md:px-28 flex justify-between">
                <div className="flex mt-4">
                  <div
                    className="cursor-pointer"
                    onClick={(e) => this._handelback()}>
                    <img
                      className="pl-2 pt-1"
                      src="../../images/backicon.png"
                    />
                  </div>
                  <div
                    className="pl-2 cursor-pointer text-sm text-gray500 font-medium"
                    onClick={(e) => this._handelback()}>
                    {LocaleStrings.common_goback}
                  </div>
                </div>
                <div className="mx-auto mt-4 font-bold">
                  {LocaleStrings.setting_privacy_privacy_header}
                </div>
              </div>
            </div>

            <div className="xs:px-6 sm:px-12 lg:px-28 py-10 h-5/6 overflow-auto">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. All the Lorem Ipsum generators on
              the Internet tend to repeat predefined chunks as necessary, making
              this the first true generator on the Internet. It uses a
              dictionary of over 200 Latin words, combined with a handful of
              model sentence structures, to generate Lorem Ipsum which looks
              reasonable. The generated Lorem Ipsum is therefore always free
              from repetition, injected humour, or non-characteristic words etc.
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. All the Lorem Ipsum generators on
              the Internet tend to repeat predefined chunks as necessary, making
              this the first true generator on the Internet. It uses a
              dictionary of over 200 Latin words, combined with a handful of
              model sentence structures, to generate Lorem Ipsum which looks
              reasonable. The generated Lorem Ipsum is therefore always free
              from repetition, injected humour, or non-characteristic words etc.
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. All the Lorem Ipsum generators on
              the Internet tend to repeat predefined chunks as necessary, making
              this the first true generator on the Internet. It uses a
              dictionary of over 200 Latin words, combined with a handful of
              model sentence structures, to generate Lorem Ipsum which looks
              reasonable.
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  var { session, user } = state;
  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
  };
}

export default connect(mapStateToProps, {})(PrivacyPolicy);
