import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import { BaseComponent } from "@baseComponent";
import { classNames } from "@commonFunction";
import LocaleStrings from "@language";
import { APP_API_KEY, BASE_IMAGES_URL } from "@constant";

class TransactionHistoryItem extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      btnLoader: false,
    };
  }
  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }

  copyEmailToClipboard = (e, values) => {
    navigator.clipboard.writeText(values.invoicenumber);
    toast.success(LocaleStrings.copied);
  };

  downloadPdf = (values) => {
    if (values.invoicenumber !== null) {
      var filename = "invoice.pdf";
      var uri = `${BASE_IMAGES_URL}/transactionhistorypdf/${values.invoicenumber}.pdf?api_key=${APP_API_KEY}&session_token=${this.props.session.sessionToken}&download=true`;
      var link = document.createElement("a");
      link.href = uri;
      link.style = "visibility:hidden";
      link.download = filename;
      link.target = "_blank";
      //this part will append the anchor tag and remove it after automatic click
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      this.props.showError(LocaleStrings.common_fail_message);
    }
  };

  handleindex = (nmbr) => {
    if (nmbr % 2 == 0) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    var { values, keyindex } = this.props;

    let transactiondate = "-";
    let expirydate = "-";
    let periodfrom = "";
    let periodto = "";
    if (values.transactiondate !== null && values.transactiondate !== "") {
      transactiondate = moment(values.transactiondate).format("YYYY-MM-DD");
      expirydate = moment(values.expirydate).format("YYYY-MM-DD");
    }
    periodfrom = new Date(transactiondate).toLocaleString("en-us", {
      month: "long",
    });
    periodto = new Date(expirydate).toLocaleString("en-us", { month: "long" });

    let indexisodd1 = this.handleindex(keyindex);
    return (
      <>
        <tr className={classNames(indexisodd1 ? "bg-white" : "chatbg", "")}>
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
            <div className="flex items-center">
              <div
                className="h-10 w-10 flex-shrink-0 cursor-pointer"
                onClick={(e) => this.copyEmailToClipboard(e, values)}>
                <img src="../../images/copy.png" />
              </div>
              <div className="ml-4">
                <div className="font-medium text-gray-900">
                  {" "}
                  {values.invoicenumber}
                </div>
              </div>
            </div>
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {/* {values.isaddon == '0' || values.isaddon == 0 ? ( */}
            <div>
              {" "}
              {periodfrom} - {periodto}
            </div>
            {/* ):('-')} */}
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {transactiondate}
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {values.planname}{" "}
            {values.isaddon == "1" || values.isaddon == 1 ? (
              <span className="text-green-600">(Add on)</span>
            ) : (
              ""
            )}
          </td>
          <td className="whitespace-nowrap pl-8 py-4 text-sm text-gray-500 2xl:text-center">
            ${values.price}
          </td>
          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm text-gray-500 mt-2 sm:pr-6 flex">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => this.downloadPdf(values)}>
              <div className="h-10 w-10 flex-shrink-0">
                <img src="../../images/download.png" />
              </div>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  return {
    session,
    user,
  };
}
export default connect(mapStateToProps, {})(TransactionHistoryItem);
