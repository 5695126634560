import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, reset } from "redux-form";
import LocaleStrings from "@language";
import _ from "lodash";
import toast, { Toaster } from "react-hot-toast";
import { classNames } from "@commonFunction";
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { BaseComponent, LABEL_POSITION_TOP } from "@baseComponent";
import { fetchApikeys, updateApi } from "@settingsApiActions";
import { fetchAppuser } from "@sidebarActions";

class EditApi extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isBtnDisable: false,
      btnLoader: false,
    };
    this.closeModal = this.closeModal.bind(this);
  }
  componentWillUnmount() {}

  closeModal() {
    this.props.onClose();
  }

  /***** Form Submit *****/
  onFormSubmit(values) {
    let { user, session } = this.props;
    if (values.apikey && values.purpose) {
      this.setState({ isBtnDisable: true });
      this.setState({ btnLoader: true });
      let obj = {};
      obj.keyid = values.keyid;
      // obj.clientid = values.clientid;
      obj.purpose = values.purpose;

      this.props.updateApi(session, obj, (callback) => {
        if (callback && callback.success == 1) {
          this.props.fetchApikeys(session, (callback) => {
            this.props.fetchAppuser(session, user?.appuserid);
            toast.success(LocaleStrings.settings_api_updatesuccess);
            this.setState({ isBtnDisable: false });
            this.setState({ btnLoader: false });
            this.closeModal();
          });
        }
      });
    } else {
      toast.error(LocaleStrings.common_fail_message);
    }
  }

  render() {
    let { handleSubmit } = this.props;
    return (
      <>
        <Transition.Root show={this.props.isOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.isOpen}
            onClose={this.props.onClose}
            initialFocus={this.myRef}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block rounded-xl align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-3/12 "
                  style={{ padding: 0 }}>
                  <form
                    className=" "
                    onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
                    <div className="p-6 border-t-2 border-quaternary">
                      <div className="pb-6">
                        <img
                          src="../../images/createapi/createapi.png"
                          className=""></img>
                      </div>
                      <div className="pb-3 text-lg mainGray">
                        {LocaleStrings.settings_api_updateapi}
                      </div>
                      <div className="pb-6 text-sm text-gray500 font-normal">
                        {LocaleStrings.settings_api_updatepurpose}
                      </div>
                      <div className="pb-6">
                        <Field
                          name="apikey"
                          label={
                            LocaleStrings.settings_crtapi_modal_label_keyname
                          }
                          placeholder={
                            LocaleStrings.settings_crtapi_modal_placeholder_keyname
                          }
                          type="text"
                          isdisabled="true"
                          component={this.renderFieldText}
                          mandatory="true"
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-sm font-normal text-gray700"
                        />
                      </div>
                      <div className="pb-6">
                        <Field
                          name="purpose"
                          label={
                            LocaleStrings.settings_crtapi_modal_label_apipurpose
                          }
                          placeholder={
                            LocaleStrings.settings_crtapi_modal_placeholder_apipurpose
                          }
                          type="text"
                          component={this.renderFieldText}
                          mandatory="true"
                          labelposition={LABEL_POSITION_TOP}
                          classNameLabel="text-sm font-normal text-gray700"
                        />
                      </div>
                      <div className="flex">
                        <button
                          type="button"
                          onClick={this.closeModal}
                          className="btn-white text-primary cursor-pointer">
                          {LocaleStrings.cancel}
                        </button>
                        {/* Footer */}
                        <button
                          type="submit"
                          disabled={this.state.isBtnDisable}
                          className={classNames(
                            this.state.isBtnDisable ? "cursor-not-allowed" : "",
                            "btn-primary ml-3"
                          )}>
                          <svg
                            className={classNames(
                              this.state.btnLoader ? "" : "hidden",
                              "animate-spin-medium h-5 w-5 rounded-full mx-2"
                            )}
                            disabled={this.state.isBtnDisable}
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="loading"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true">
                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                          </svg>
                          {LocaleStrings.settings_api_updatebtn}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function validate(values) {
  var errors = {};
  var requiredFields = ["apikey", "purpose"];
  requiredFields.forEach((field) => {
    if (!values[field] || _.trim(values[field]) === "") {
      errors[field] = LocaleStrings.required;
    }
  });
  return errors;
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    iscreateapimodalOpen: state.iscreateapimodalOpen,
    initialValues: state.editapiDatalist,
  };
}

export default connect(mapStateToProps, {
  fetchApikeys,
  updateApi,
  fetchAppuser,
})(
  reduxForm({
    validate,
    form: "EditApiForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(EditApi)
);
