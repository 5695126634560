

import {ADD_MEMBER_MODAL,ALL_MEMBERLIST} from "@manageseatAction";

export var isaddmembermodalOpen = (state = false, action) => {
    if (action.type === ADD_MEMBER_MODAL) {
      state = action.payload;
    }
  
    return state;
  };

  export var memberList = (state = {}, action) => {
    switch (action.type) {
      case ALL_MEMBERLIST:
        return action.payload;
        break;
      default:
        return state;
        break;
    }
    }

    export var roles = (state = [], action) => {
      //iconName is optional
      var state = [
        { name: "Admin", value: "admin",id:0 },
        { name: "Member", value: "member",id:1 },
        // { name: "Bottom", value:"bottom" ,id:2 },  
      ];
    
      return state;
    };