import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import BaseLoader from '@baseLoader';
import LocaleStrings from '@language';
import {fetchsubscriptionplans} from '@settingsPlansActions';
import UpgradeplanItemsSettings from './planlist-items';
import {fetchCurrentMemberCount, fetchApikeyCount} from '@upgradePlanActions';

class Plans extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarOpen: false,
      loading: true,
      loaderDesc: LocaleStrings.preparing_subscription_plan,
      selectMenuNum: 1,
      selectedThreadId: '',
      selectedTenure: 30,
    };
    // All Binded Functions
  }

  componentDidMount() {
    this.props.fetchsubscriptionplans(this.props.session, callback => {
      this.props.fetchCurrentMemberCount(this.props.session, callback => {
        this.props.fetchApikeyCount(this.props.session, callback => {
          this.setState({loading: false});
        });
      });
    });
  }

  renderplanList() {
    var planList = _.filter(
      this.props.subscriptionplanListsettings.data,
      obj => {
        return obj.validity === this.state.selectedTenure;
      },
    );
    var items = _.map(planList, (values, index) => (
      <UpgradeplanItemsSettings
        key={index}
        values={values}
        history={this.props.history}
      />
    ));

    return items;
  }

  changePlan(key) {
    this.setState({selectedTenure: key});
  }

  render() {
    let {subscriptionplanListsettings} = this.props;
    let {selectedTenure} = this.state;

    return (
      <>
        <div className="xs:px-6 md:px-0">
          <div className="text-2xl font-semibold text-mainGray">
            {LocaleStrings.upgradeplan_header}
          </div>
          <div className="text-sm text-gray500 mt-3 font-normal">
            {LocaleStrings.upgradeplan_desc}
          </div>
          {/* Commented for now */}

          <div className="py-2">
            <span className="isolate inline-flex rounded-lg bg-gray-100 p-0.5 ">
              <button
                type="button"
                className={`relative w-2/2 whitespace-nowrap rounded-md  py-2 text-sm font-medium text-gray-900 shadow-sm focus:z-10 focus:outline-none  sm:w-auto px-2 sm:px-8 ${
                  selectedTenure == 30 ? 'bg-white border-gray-200' : ''
                }`}
                onClick={() =>
                  selectedTenure == 30 ? null : this.changePlan(30)
                }>
                {LocaleStrings.upgradeplan_desc_tab_button1}
              </button>
              <button
                type="button"
                className={`relative w-2/2 whitespace-nowrap rounded-md  py-2 text-sm font-medium text-gray-900 shadow-sm focus:z-10 focus:outline-none   sm:w-auto px-2 sm:px-8 ${
                  selectedTenure == 365 ? 'bg-white border-gray-200' : ''
                }`}
                onClick={() =>
                  selectedTenure == 365 ? null : this.changePlan(365)
                }>
                {LocaleStrings.upgradeplan_desc_tab_button2}
              </button>
            </span>
          </div>
          {this.state.loading ? (
            <>
              <BaseLoader data={this.state.loaderDesc} />
            </>
          ) : (
            <>
              {subscriptionplanListsettings &&
              subscriptionplanListsettings.data &&
              subscriptionplanListsettings.data.length > 0 ? (
                <>
                  <div className="space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
                    {this.renderplanList()}
                  </div>
                </>
              ) : (
                <div className="center-item lg:my-48 text-lg text-ternary">
                  {LocaleStrings.no_data_found}
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  subscriptionplanListsettings: state.subscriptionplanListsettings,
});

export default connect(mapStateToProps, {
  fetchsubscriptionplans,
  fetchCurrentMemberCount,
  fetchApikeyCount,
})(Plans);
