import React from 'react';
// import Search from '../../../../resources/images/search-blue.png';
import {SearchIcon} from '@heroicons/react/solid';

function NoContact(props) {
  return (
    <div className="flex flex-col items-center justify-center my-20">
      <SearchIcon className="w-10 text-primary" />
      <div className="mt-6 font-medium text-lg text-gray-500 text-center">
        {props.validProfileUrl
          ? 'No contact details found.'
          : 'Use search options to find contacts'}
      </div>
      <div className="mt-4 mx-5 text-sm text-gray-400 text-center">
        {props.validProfileUrl
          ? 'No details matched to find the contact details. Please try another url.'
          : ''}
      </div>
    </div>
  );
}
export default NoContact;
