import {
  CREATEAPI_MODAL,
  APIKEYS_DATALIST,
  EDITAPIDATA,
  TRYOUTAPI_MODAL,
  DOCUMENTATION_MODAL,
} from "@settingsApiActions";

export var iscreateapimodalOpen = (state = false, action) => {
  if (action.type === CREATEAPI_MODAL) {
    state = action.payload;
  }

  return state;
};
export var isapidocumentationmodalOpen = (state = false, action) => {
  if (action.type === DOCUMENTATION_MODAL) {
    state = action.payload;
  }

  return state;
};
export var istryoutapimodalOpen = (state = false, action) => {
  if (action.type === TRYOUTAPI_MODAL) {
    state = action.payload;
  }

  return state;
};

export var apikeysDatalist = (state = {}, action) => {
  switch (action.type) {
    case APIKEYS_DATALIST:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var editapiDatalist = (state = {}, action) => {
  switch (action.type) {
    case EDITAPIDATA:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
