import {useState} from 'react';
import {connect} from 'react-redux';
import LocaleStrings from '../../../languages';
import {BaseComponent} from '@baseComponent';
import {classNames} from '@commonFunction';
import iconvalid from '../../../../assets/images/mycontacts/valid.png';
import iconunknown from '../../../../assets/images/mycontacts/unknown.png';
import toast, {Toaster} from 'react-hot-toast';
import iconvalidinfo from '../../../../assets/images/mycontacts/iconvalid.png';
import iconunknowninfo from '../../../../assets/images/mycontacts/iconunknown.png';
import {parse} from '../../../../common/common-utils';

function BusinessContact(props) {
  const [mainvalue] = useState(props.mainvalue);
  const [val] = useState(props.val);
  const [showBusinessEmailInfo, setShowBusinessEmailInfo] = useState(false);
  const [showEmailOptions, setShowEmailOptions] = useState(false);

  // copy email/phone
  const _handleCopy = (e, val, mainvalue, type) => {
    if (type == 'email') {
      // console.log("val", val);
      if (!val.address) {
        navigator.clipboard.writeText(val);
      } else {
        navigator.clipboard.writeText(val.address);
      }
      // navigator.clipboard.writeText(val.address);
    } else {
      navigator.clipboard.writeText(val);
    }

    toast.success('Copied');
  };

  // email upvoting
  const _handleemailupvote = (e, val, mainvalue) => {
    props.onUpvote(val.address);
  };

  // email downvoting
  const _handleemaildownvote = (e, val, mainvalue) => {
    props.onDownvote(val.address);
  };

  return (
    <div className="flex items-center space-x-4">
      <div class="cursor-pointer flex">
        <span
          className="items-center justify-center mt-1 absolute"
          onMouseEnter={() => {
            if (val !== '-') {
              setShowBusinessEmailInfo(true);
            }
          }}
          onMouseLeave={() => {
            setShowBusinessEmailInfo(false);
          }}>
          {mainvalue.bus_email_validation !== null ? (
            mainvalue.bus_email_validation === 'unknown' ? (
              <img src={iconunknown} className="cursor-pointer" width={28} />
            ) : mainvalue.bus_email_validation === 'valid' ? (
              <img src={iconvalid} className="cursor-pointer" width={28} />
            ) : null
          ) : null}
        </span>
        <div
          class="px-7 py-1 mt-0.5"
          onMouseEnter={() => {
            if (val !== '-') {
              setShowEmailOptions(true);
            }
          }}
          onMouseLeave={() => {
            setShowEmailOptions(false);
          }}>
          {val}
        </div>
        {showEmailOptions ? (
          <div
            class="right-0  
          -translate-x-1/2 translate-y-full mx-auto mt-1"
            onMouseEnter={() => {
              if (val !== '-') {
                setShowEmailOptions(true);
              }
            }}
            onMouseLeave={() => {
              setShowEmailOptions(false);
            }}>
            <div className="absolute bg-white z-50 rounded-md">
              <span className="isolate inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  onClick={e => _handleCopy(e, val, mainvalue, 'email')}
                  className="relative inline-flex items-center rounded-l-md border border-gray-300 px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50  focus:outline-none ">
                  <img
                    height={16}
                    width={16}
                    src="../../images/copysmall.png"></img>
                </button>
                <button
                  type="button"
                  onClick={e => _handleemailupvote(e, val, mainvalue)}
                  className="relative -ml-px inline-flex items-center border border-gray-300 px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 ">
                  <img height={16} width={16} src="../../images/like.png"></img>
                </button>
                <button
                  type="button"
                  onClick={e => _handleemaildownvote(e, val, mainvalue)}
                  className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50">
                  <img
                    height={16}
                    width={16}
                    src="../../images/dislike.png"></img>
                </button>
              </span>
            </div>
          </div>
        ) : null}
        {showBusinessEmailInfo ? (
          <div
            className="shadow absolute flex flex-wrap border-gray-200 rounded-lg border border-solid pt-2 pb-2 pl-3 pr-3 ml-2 mt-8 back bg-gray-600"
            style={{zIndex: 999}}>
            {mainvalue.bus_email_validation === 'unknown' ? (
              <div>
                <div className="flex flex-col mt-0 relative">
                  {/* <span className="items-center justify-center mr-2 mt-1">
                    <img src={iconunknowninfo} className="w-full h-auto" />
                  </span> */}
                  <span className="font-sm text-white">
                    {`${LocaleStrings.partially_verified} (${LocaleStrings.free})`}
                  </span>
                  {/* <span className="absolute right-0 text-xs font-normal font-inter bg-white p-1 rounded-md text-gray-600">
                    {LocaleStrings.free}
                  </span> */}
                </div>
                {/* <div className="text-sm font-bold font-inter text-white">
                  {LocaleStrings.partially_rate}
                </div>
                <div
                  className="text-xs font-normal text-white leading-tight"
                  style={{whiteSpace: 'initial'}}>
                  {LocaleStrings.partially_text}
                </div> */}
              </div>
            ) : (
              <div>
                <div className="flex flex-col">
                  {/* <span className="items-center justify-center mr-2 mt-1">
                    <img src={iconvalidinfo} className="w-full h-auto" />
                  </span> */}
                  <span className="font-sm text-white">
                    {LocaleStrings.fully_verified}
                  </span>
                </div>
                {/* <div className="text-sm font-bold font-inter text-white">
                  {LocaleStrings.fully_rate}
                </div>
                <div
                  className="flex text-xs font-normal text-white leading-tight break-normal"
                  style={{whiteSpace: 'initial'}}>
                  {LocaleStrings.fully_text}
                </div> */}
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  var {session, user, appuserData} = state;

  return {
    session,
    user,
    appuserData,
  };
}

export default connect(mapStateToProps, null)(BusinessContact);
