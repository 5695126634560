import {
  getRequest,
  postRequest,
  deleteRequest,
  fileUploadRequest,
  patchRequest,
  deleteBulkRequest,
} from '@networkCall';

import {
  APP_API_KEY,
  INSTANCE_URL,
  itemCount,
  DEVELOPMENT_TYPE,
} from '@constant';

import LocaleStrings from '@language';

// All Exports
export var ALL_REVEALEDCOUNT = 'ALL_REVEALEDCOUNT';
export var ALL_REVEALEDCOUNT_DIRECTDIALS = 'ALL_REVEALEDCOUNT_DIRECTDIALS';

export function fetchRevealedcontact(session, appuserid, date, callback) {
  var filter = encodeURI(
    `filter=(revealedby=${appuserid})AND(createdon > ${date})`,
  );
  var url = `${INSTANCE_URL}/api/v2/thomsondata/_table/usercontact?${filter}&include_count=true`;
  return dispatch => {
    getRequest(
      url,
      session,
      dispatch,
      data => {
        dispatch({
          type: ALL_REVEALEDCOUNT,
          payload: {data: data.meta.count},
        });
        callback({success: 1, data: data});
      },
      error => {},
    );
  };
}
export function fetchRevealedcontactbydirectdials(
  session,
  appuserid,
  date,
  callback,
) {
  var filter = encodeURI(
    `filter=(dialrevealedby=${appuserid})AND(createdon > ${date})`,
  );
  var url = `${INSTANCE_URL}/api/v2/thomsondata/_table/usercontact?${filter}&include_count=true`;
  return dispatch => {
    getRequest(
      url,
      session,
      dispatch,
      data => {
        dispatch({
          type: ALL_REVEALEDCOUNT_DIRECTDIALS,
          payload: {data: data.meta.count},
        });
        callback({success: 1, data: data});
      },
      error => {},
    );
  };
}

export function fetchRevealedCounts(session, appuserid, date, callback) {
  const params = {revealedby: appuserid, date};
  const url = `${INSTANCE_URL}/api/v2/getrevealcount`;
  return dispatch => {
    postRequest(
      url,
      params,
      session,
      dispatch,
      response => {
        dispatch({
          type: ALL_REVEALEDCOUNT,
          payload: {...response},
        });
      },
      error => {
        callback({success: 0, message: error});
      },
    );
  };
}
