import axios from "axios";
import { APP_API_KEY, INSTANCE_URL, LOGIN } from "@constant";
import {
  postRequest,
  patchRequest,
  postRequestAccess,
  getRequest,
  postRequestnew,
} from "@networkCall";

import _ from "lodash";

export function sendEmailfordemo(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/scheduledemoemail`;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        // console.log('res',response)
        callback({ success: 1, result: response });
      },
      (error) => {
        // console.log(error)
        callback({ success: 0, message: error });
      }
    );
  };
}
