import {
  ALLAPPUSERS,
  OPEN_REVEALED_MODAL,
  SELECTED_APPUSERID_FOR_REVEALEDBY,
  REVEALEDCONTACTS_FOR_SELECTED_APPUSERID,
  STORE_APPUSERNAME,
  STORE_APPUSERDATA,
  APIUSAGE_DATA,
} from "@settingsUsageActions";

export var allappuserdataList = (state = {}, action) => {
  switch (action.type) {
    case ALLAPPUSERS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var revealedcontactdataList = (state = {}, action) => {
  switch (action.type) {
    case REVEALEDCONTACTS_FOR_SELECTED_APPUSERID:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

export var apiusagedataList = (state = {}, action) => {
  switch (action.type) {
    case APIUSAGE_DATA:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

export var isrevealedbyOpen = (state = false, action) => {
  if (action.type === OPEN_REVEALED_MODAL) {
    state = action.payload;
  }

  return state;
};

export var storedrevealbyappuserdata = (state = {}, action) => {
  if (action.type === STORE_APPUSERDATA) {
    state = action.payload;
  }

  return state;
};

export var storedrevealbyappusername = (state = "", action) => {
  if (action.type === STORE_APPUSERNAME) {
    state = action.payload;
  }

  return state;
};
