import { TRANSACTION_HISTORY } from "@settingsBillingActions";

export var transactionhistorydataList = (state = {}, action) => {
  switch (action.type) {
    case TRANSACTION_HISTORY:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
