import {
  ALL_SUBSCRIPTION_PLANS,
  MEMBER_AND_ADMINLISTCOUNT,
  APIKEYS_COUNT,
} from "@upgradePlanActions";

export var subscriptionplanList = (state = {}, action) => {
  switch (action.type) {
    case ALL_SUBSCRIPTION_PLANS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var memberadminListcount = (state = {}, action) => {
  switch (action.type) {
    case MEMBER_AND_ADMINLISTCOUNT:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
export var apikeysListcount = (state = {}, action) => {
  switch (action.type) {
    case APIKEYS_COUNT:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
