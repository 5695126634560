import {useState} from 'react';
import {connect} from 'react-redux';
import LocaleStrings from '../../../languages';
import toast, {Toaster} from 'react-hot-toast';
import {contactmetaAddupdate, revealEmail} from '@myContactsActions';

const IconImageSize = 16;

function PersonalContact(props) {
  const [mainvalue] = useState(props.mainvalue);
  const [val] = useState(props.val);

  const [showEmailOptions, setShowEmailOptions] = useState(false);

  const _handleCopy = (e, val, mainvalue, type) => {
    if (type == 'email') {
      // console.log("val", val);
      if (!val.address) {
        navigator.clipboard.writeText(val);
      } else {
        navigator.clipboard.writeText(val.address);
      }
      // navigator.clipboard.writeText(val.address);
    } else {
      navigator.clipboard.writeText(val);
    }

    toast.success('Copied');
  };

  // email upvoting
  const _handleemailupvote = (e, val, mainvalue) => {
    props.onUpvote(val.address);
  };

  // email downvoting
  const _handleemaildownvote = (e, val, mainvalue) => {
    props.onDownvote(val.address);
  };

  return (
    <div className="flex items-center space-x-4" key={val.contactid}>
      <div class="group cursor-pointer flex relative">
        <div
          class="px-2 py-1"
          onMouseEnter={() => {
            setShowEmailOptions(true);
          }}
          onMouseLeave={() => {
            setShowEmailOptions(false);
          }}>
          {val.address}
        </div>

        <div
          class="right-0  
        -translate-x-1/2 translate-y-full w-24"
          onMouseEnter={() => {
            setShowEmailOptions(true);
          }}
          onMouseLeave={() => {
            setShowEmailOptions(false);
          }}>
          {showEmailOptions ? (
            <div className="absolute bg-white z-50 rounded-md">
              <span className="isolate inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  onClick={e => _handleCopy(e, val, mainvalue, 'email')}
                  className="relative inline-flex items-center rounded-l-md border border-gray-300 px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50  focus:outline-none ">
                  <img
                    height={IconImageSize}
                    width={IconImageSize}
                    src="../../images/copysmall.png"></img>
                </button>
                <button
                  type="button"
                  onClick={e => _handleemailupvote(e, val, mainvalue)}
                  className="relative -ml-px inline-flex items-center border border-gray-300 px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 ">
                  <img
                    height={IconImageSize}
                    width={IconImageSize}
                    src="../../images/like.png"></img>
                </button>
                <button
                  type="button"
                  onClick={e => _handleemaildownvote(e, val, mainvalue)}
                  className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50">
                  <img
                    height={IconImageSize}
                    width={IconImageSize}
                    src="../../images/dislike.png"></img>
                </button>
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  var {session, user, appuserData} = state;

  return {
    session,
    user,
    appuserData,
  };
}

export default connect(mapStateToProps, null)(PersonalContact);
