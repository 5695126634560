import { getRequest, postRequest } from "@networkCall";
import { INSTANCE_URL } from "@constant";

export var ADDPAYMENT_MODAL = "ADDPAYMENT_MODAL";
export var SELECTED_ADDON = "SELECTED_ADDON";
export var ADDON_SUBSCRIPTIONS = "ADDON_SUBSCRIPTIONS";

export function addpaymentModal(isOpen) {
  return { type: ADDPAYMENT_MODAL, payload: isOpen };
}

export function selectedaddOn(values) {
  return (dispatch) => {
    dispatch({ type: SELECTED_ADDON, payload: values });
  };
}

export function fetchAddons(session, callback) {
  var url = `${INSTANCE_URL}/api/v2/thomsondata/_table/subscriptionplan?filter=(isaddon=1)&order=planid%20ASC`;
  return (dispatch) => {
    getRequest(
      url,
      session,
      dispatch,
      (data) => {
        dispatch({
          type: ADDON_SUBSCRIPTIONS,
          payload: { data: data.resource },
        });
        callback({ success: 1, data: data });
      },
      (error) => {}
    );
  };
}

export function payforAddon(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/addonpayment`;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        // console.log('res',response)
        callback({ success: 1, result: response });
      },
      (error) => {
        // console.log(error)
        callback({ success: 0, message: error });
      }
    );
  };
}

export function fetchpaymentIntent(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/addonpaymentintent`;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        // console.log('res',response)
        callback({ success: 1, result: response });
      },
      (error) => {
        // console.log(error)
        callback({ success: 0, message: error });
      }
    );
  };
}

export function addoncredits_email(session, values, callback) {
  let url = `${INSTANCE_URL}/api/v2/addoncredits_email`;
  return (dispatch) => {
    postRequest(
      url,
      values,
      session,
      dispatch,
      (response) => {
        // console.log('res',response)
        callback({ success: 1, result: response });
      },
      (error) => {
        // console.log(error)
        // callback({ success: 0, message: error });
      }
    );
  };
}
