import React from 'react';
import {connect} from 'react-redux';
import {Dialog, Transition} from '@headlessui/react';
import LocaleStrings from '@language';
import {Fragment} from 'react';
import {BaseComponent} from '@baseComponent';
import BaseLoader from '@baseLoader';
import {Field, reduxForm} from 'redux-form';
import _ from 'lodash';
import RevealedContactListitems from './revealedcontacts-item';
import {
  openRevealedmodal,
  fetchrevealedContacts,
  revealedbyappuserdata,
  revealedbyappusername,
} from '@settingsUsageActions';

class RevealedBy extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_revealed_contacts,
      btnLoader: false,
      enar: 'en',
      pageNumber: 1,
      numsPerPage: 10,
    };

    // All Binded Functions
    this.closeModal = this.closeModal.bind(this);
  }
  componentDidMount() {
    let {session, appuserData} = this.props;

    // console.log("appuserData", appuserData);

    if (this.props.appuserid && this.props.appuserid !== '') {
      this.props.fetchrevealedContacts(
        session,
        this.props.appuserid,
        this.state.pageNumber,
        res => {
          this.calculateLastPage(res);
          this.setState({loading: false});
        },
      );
    }
  }

  handlePrevPagination = () => {
    let {pageNumber, search, perPage} = this.state;
    let {session, user} = this.props;
    if (pageNumber <= 1) {
    } else {
      let number = pageNumber > 1 ? pageNumber - 1 : 1;
      this.props.fetchrevealedContacts(
        session,
        this.props.appuserid,
        number,
        res => {},
      );
      this.setState({pageNumber: number});
    }
  };

  navigateToFirst = () => {
    let {session, user, perPage} = this.props;
    let {search} = this.state;
    this.props.fetchrevealedContacts(session, this.props.appuserid, 1, res => {
      if (res.success) {
        this.calculateLastPage(res);
      }
    });
    this.setState({pageNumber: 1});
  };

  navigateToLast = () => {
    let {session, user, appuserid} = this.props;
    let {lastPageNumber, search, perPage} = this.state;
    this.props.fetchrevealedContacts(
      session,
      appuserid,
      lastPageNumber,
      res => {},
    );
    this.setState({lastPageNumber});
  };

  calculateLastPage = response => {
    let perPage = this.state.numsPerPage;

    let number =
      response.count % parseInt(perPage) > 0
        ? Math.floor(response.count / parseInt(perPage)) + 1
        : Math.floor(response.count / parseInt(perPage));
    // // let number =
    // //     response.count % 50 > 0
    // //         ? Math.floor(response.count / 50) + 1
    // //         : Math.floor(response.count / 50);
    // const nums = this.state.numsPerPage
    // let number = nums % 50 > 0 ? Math.floor(nums / 50) + 1 : Math.floor(nums / 50);

    this.setState({lastPageNumber: number});
  };

  handleNextPagination = () => {
    let {session, user} = this.props;
    let {lastPageNumber, search, pageNumber, perPage} = this.state;
    if (pageNumber < lastPageNumber) {
      let number = pageNumber + 1;
      this.props.fetchrevealedContacts(
        session,
        this.props.appuserid,
        number,
        res => {},
      );
      this.setState({pageNumber: number});
    }
  };

  closeModal() {
    this.props.revealedbyappuserdata({});
    this.props.openRevealedmodal(false);
    setTimeout(() => {
      this.props.revealedbyappusername('');
    }, 1000);
  }

  renderrevealedcontact() {
    let {revealedcontactdataList} = this.props;
    let pageDataLength = revealedcontactdataList.data.length;
    let {pageNumber} = this.state;
    var items = _.map(revealedcontactdataList.data, (values, index) => (
      <RevealedContactListitems
        key={values.contactid}
        values={values}
        pageDataLength={pageDataLength}
        currentPage={pageNumber}
      />
    ));
    return items;
  }

  render() {
    let {pageNumber, lastPageNumber} = this.state;
    let {revealedcontactdataList, appuserData, storedrevealbyappusername} =
      this.props;
    let name = 'No Name';
    if (storedrevealbyappusername) {
      name = storedrevealbyappusername;
    }
    return (
      <Transition.Root show={this.props.isrevealedbyOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={this.props.isrevealedbyOpen}
          onClose={this.closeModal}
          initialFocus={this.myRef}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div
                className="inline-block  align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 sticky-top scrollbar-hide bigmodal"
                style={{padding: 0}}>
                <>
                  {this.state.loading ? (
                    <>
                      <BaseLoader data={this.state.loaderDesc} />
                    </>
                  ) : (
                    <>
                      <div className="p-6">
                        <div className="">
                          {
                            LocaleStrings.setting_usage_reavealedcontact_modal_header
                          }
                        </div>
                        <div className="mt-3 text-sm font-medium text-gray500">
                          {
                            LocaleStrings.setting_usage_reavealedcontact_modal_subheader
                          }{' '}
                          {name}{' '}
                        </div>

                        {revealedcontactdataList &&
                        revealedcontactdataList.data &&
                        revealedcontactdataList.data.length > 0 ? (
                          <>
                            <div className="flex flex-col mt-6">
                              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                  <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    <table className="min-w-full table-fixed divide-y divide-gray-300">
                                      <thead className="bg-gray-50">
                                        <tr>
                                          <th
                                            scope="col"
                                            className="min-w-[12rem] py-3.5 px-6 text-left text-sm font-medium text-gray500">
                                            {LocaleStrings.mycontacts_th1}
                                          </th>

                                          <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-medium text-gray500">
                                            {LocaleStrings.mycontacts_th3}
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-medium text-gray500">
                                            {LocaleStrings.mycontacts_th4}
                                          </th>
                                          <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-medium text-gray500">
                                            {LocaleStrings.mycontacts_th5}
                                          </th>
                                          <th
                                            scope="col"
                                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"></th>
                                        </tr>
                                      </thead>
                                      <tbody className="divide-y divide-gray-200 bg-white">
                                        {this.renderrevealedcontact()}
                                      </tbody>
                                    </table>
                                    {revealedcontactdataList.data.length >
                                    this.state.numsPerPage ? (
                                      <nav className="flex items-center justify-between border-t border-gray-200 px-4 py-2">
                                        <div className="">
                                          <button
                                            className="btn-white cursor-pointer"
                                            disabled={
                                              pageNumber <= 1 ? true : false
                                            }
                                            onClick={this.handlePrevPagination}
                                            type="button"
                                            style={{
                                              cursor:
                                                pageNumber <= 1
                                                  ? 'not-allowed'
                                                  : 'pointer',
                                            }}>
                                            <img
                                              src="../../images/previous.png"
                                              className="pt-0.5 pr-2"></img>
                                            {LocaleStrings.previous}
                                          </button>
                                        </div>
                                        <div className="flex mt-2 sm:mt-auto mx-auto">
                                          <nav aria-label="Table navigation">
                                            <ul className="inline-flex items-center">
                                              {/* ----- prev button ----- */}
                                              <li>
                                                <button
                                                  className="px-3 py-1 rounded-md rounded-l-lg focus:outline-none"
                                                  aria-label="Previous"
                                                  disabled={
                                                    pageNumber <= 1
                                                      ? true
                                                      : false
                                                  }
                                                  onClick={
                                                    this.handlePrevPagination
                                                  }
                                                  style={{
                                                    cursor:
                                                      pageNumber <= 1
                                                        ? 'not-allowed'
                                                        : 'pointer',
                                                  }}>
                                                  <svg
                                                    className="w-4 h-4 fill-current"
                                                    aria-hidden="true"
                                                    viewBox="0 0 20 20">
                                                    <path
                                                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                                      clipRule="evenodd"
                                                      fillRule="evenodd"></path>
                                                  </svg>
                                                </button>
                                              </li>

                                              {/* ----- pagination numbers ----- */}
                                              {revealedcontactdataList &&
                                              revealedcontactdataList.count ? (
                                                <>
                                                  {pageNumber == 1 ? (
                                                    <></>
                                                  ) : pageNumber == 2 ? (
                                                    <>
                                                      <li>
                                                        <button
                                                          className="px-3 py-1 rounded-md focus:outline-none"
                                                          onClick={
                                                            this.navigateToFirst
                                                          }>
                                                          1
                                                        </button>
                                                      </li>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <li>
                                                        <button
                                                          className="px-3 py-1 rounded-md focus:outline-none"
                                                          onClick={
                                                            this.navigateToFirst
                                                          }>
                                                          1
                                                        </button>
                                                      </li>
                                                      <li>
                                                        <span className="px-3 py-1">
                                                          ...
                                                        </span>
                                                      </li>
                                                    </>
                                                  )}
                                                  <li>
                                                    <button className="px-3 py-1 text-white transition-colors duration-150 bg-primaryHover text-primary  rounded-md focus:outline-none">
                                                      {/* <button className="px-3 py-1 text-white transition-colors duration-150 bg-primary border border-r-0 border-primary rounded-md focus:outline-none"> */}
                                                      {pageNumber}
                                                    </button>
                                                  </li>

                                                  {pageNumber ==
                                                  lastPageNumber ? (
                                                    <></>
                                                  ) : pageNumber ==
                                                    lastPageNumber - 1 ? (
                                                    <>
                                                      <li>
                                                        <button
                                                          className="px-3 py-1 rounded-md focus:outline-none"
                                                          onClick={
                                                            this.navigateToLast
                                                          }>
                                                          {lastPageNumber}
                                                        </button>
                                                      </li>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <li>
                                                        <span className="px-3 py-1">
                                                          ...
                                                        </span>
                                                      </li>
                                                      <li>
                                                        <button
                                                          className="px-3 py-1 rounded-md focus:outline-none"
                                                          onClick={
                                                            this.navigateToLast
                                                          }>
                                                          {lastPageNumber}
                                                        </button>
                                                      </li>
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                ''
                                              )}
                                              {/* ----- next button ----- */}
                                              <li>
                                                <button
                                                  className="px-3 py-1 rounded-md rounded-r-lg focus:outline-none"
                                                  aria-label="Next"
                                                  disabled={
                                                    pageNumber == lastPageNumber
                                                      ? true
                                                      : false
                                                  }
                                                  onClick={
                                                    this.handleNextPagination
                                                  }
                                                  style={{
                                                    cursor:
                                                      pageNumber !=
                                                      lastPageNumber
                                                        ? 'pointer'
                                                        : 'not-allowed',
                                                  }}>
                                                  <svg
                                                    className="w-4 h-4 fill-current"
                                                    aria-hidden="true"
                                                    viewBox="0 0 20 20">
                                                    <path
                                                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                      clipRule="evenodd"
                                                      fillRule="evenodd"></path>
                                                  </svg>
                                                </button>
                                              </li>
                                            </ul>
                                          </nav>
                                        </div>
                                        <div className="justify-end">
                                          <button
                                            className="btn-white cursor-pointer"
                                            disabled={
                                              pageNumber == lastPageNumber
                                                ? true
                                                : false
                                            }
                                            onClick={this.handleNextPagination}
                                            type="button"
                                            style={{
                                              cursor:
                                                pageNumber != lastPageNumber
                                                  ? 'pointer'
                                                  : 'not-allowed',
                                            }}>
                                            {LocaleStrings.next}
                                            <img
                                              src="../../images/next.png"
                                              className="pt-0.5 pl-2"></img>
                                          </button>
                                        </div>
                                      </nav>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="center-item my-24 lg:my-48 text-lg text-ternary">
                            {LocaleStrings.no_data_found}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </>
                {/* <div className=" grid grid grid-cols-6 mt-24">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div className="justify-end mr-10">
                                        <button className="btn-primary cursor-pointer"
                                            onClick={this.closeModal}
                                            type="button"
                                        >
                                            Done
                                        </button>
                                    </div>
                                </div> */}
                <div className="ml-auto">
                  <div className="justify-end">
                    <button
                      className="btn-primary cursor-pointer"
                      style={{
                        marginLeft: 'auto',
                        width: '120px',
                        marginRight: '20px',
                        marginBottom: '20px',
                      }}
                      onClick={this.closeModal}
                      type="button">
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}

function validate(values) {
  // console.log("values", values);
  var errors = {};

  return errors;
}

function mapStateToProps(state, ownProps) {
  var {session, user} = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    isrevealedbyOpen: state.isrevealedbyOpen,
    revealedcontactdataList: state.revealedcontactdataList,
    storedrevealbyappusername: state.storedrevealbyappusername,
  };
}

export default connect(mapStateToProps, {
  openRevealedmodal,
  fetchrevealedContacts,
  revealedbyappuserdata,
  revealedbyappusername,
})(
  reduxForm({
    validate,
    form: 'RevealedByForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(RevealedBy),
);
