import {
  ADDPAYMENT_MODAL,
  SELECTED_ADDON,
  ADDON_SUBSCRIPTIONS,
} from "@creditAddonActions";

export var creditaddonList = (state = {}, action) => {
  switch (action.type) {
    case ADDON_SUBSCRIPTIONS:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};

export var isaddpaymentmodalOpen = (state = false, action) => {
  if (action.type === ADDPAYMENT_MODAL) {
    state = action.payload;
  }

  return state;
};

export var selectedaddOnData = (state = {}, action) => {
  switch (action.type) {
    case SELECTED_ADDON:
      return action.payload;
      break;
    default:
      return state;
      break;
  }
};
