import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import _ from 'lodash';
import {Fragment} from 'react';
import {Transition, Dialog} from '@headlessui/react';
import {BaseComponent, LABEL_POSITION_TOP} from '@baseComponent';
import {apiDocumentationmodal} from '@settingsApiActions';
import LocaleStrings from '../../../../../languages';
import {INSTANCE_URL} from '../../../../../../common/constants';

class Apidocumentation extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isBtnDisable: false,
      btnLoader: false,
    };
    this.closeModal = this.closeModal.bind(this);
  }
  componentWillUnmount() {}

  closeModal() {
    this.props.apiDocumentationmodal(false);
  }

  /***** Form Submit *****/
  onFormSubmit(values) {}

  render() {
    let {handleSubmit} = this.props;
    return (
      <>
        <Transition.Root
          show={this.props.isapidocumentationmodalOpen}
          as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-10 inset-0 overflow-y-auto"
            open={this.props.isapidocumentationmodalOpen}
            onClose={this.closeModal}
            initialFocus={this.myRef}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-ternary bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div
                  className="inline-block rounded-xl align-bottom bg-custWhite rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-screen-lg sm:w-full sm:max-h-screen-80 overflow-y-auto scrollbar-hide"
                  style={{padding: 0}}>
                  <form
                    className=" "
                    onSubmit={handleSubmit(this.onFormSubmit.bind(this))}>
                    <div className="p-6 border-t-2 border-quaternary">
                      <div className="pb-6 flex justify-center">
                        <img
                          src="../../images/createapi/createapi.png"
                          className=""></img>{' '}
                        <div className="mt-2 ml-4 text-lg mainGray">
                          {LocaleStrings.settings_api_documentation_header}
                        </div>
                      </div>
                      <div className="text-lg mainGray font-semibold">
                        {LocaleStrings.settings_api_documentation_intro}
                      </div>
                      <div className="mt-3 text-sm text-gray500 font-normal">
                        {LocaleStrings.settings_api_documentation_intro_desc1}
                      </div>

                      <div className="mt-6 text-sm text-gray500 font-normal">
                        {LocaleStrings.settings_api_documentation_intro_desc2}
                      </div>

                      <div className="mt-6 text-lg mainGray font-semibold">
                        {LocaleStrings.settings_api_documentation_http_request}
                      </div>
                      <div className="p-4 bg-primary rounded mt-2 text-white">
                        {
                          LocaleStrings.settings_api_documentation_http_method_get
                        }{' '}
                        -{' '}
                        {`${INSTANCE_URL}${LocaleStrings.settings_api_apicallurl}`}
                      </div>

                      <div className="mt-6 text-lg mainGray font-semibold">
                        {LocaleStrings.settings_api_documentation_parameters}
                      </div>
                      <div className="mt-3 text-sm text-gray500 font-normal">
                        {
                          LocaleStrings.settings_api_documentation_parameters_desc1
                        }
                      </div>
                      <div className="mt-3 text-sm text-gray500 font-normal">
                        {
                          LocaleStrings.settings_api_documentation_parameters_desc2
                        }
                      </div>
                      <div className="mt-3 text-sm text-gray500 font-normal">
                        {
                          LocaleStrings.settings_api_documentation_parameters_desc3
                        }
                      </div>
                      <div className="mt-3 text-sm text-gray500 font-normal">
                        {
                          LocaleStrings.settings_api_documentation_parameters_desc4
                        }
                      </div>
                      <div className="mt-6 text-lg mainGray font-semibold">
                        {
                          LocaleStrings.settings_api_documentation_parameters_foradminpanel
                        }
                      </div>
                      <div className="mt-3 text-sm text-gray500 font-normal">
                        {
                          LocaleStrings.settings_api_documentation_foradminpanel_desc1
                        }
                      </div>
                      <div className="mt-2 text-sm text-gray500 font-normal">
                        {
                          LocaleStrings.settings_api_documentation_foradminpanel_desc2
                        }
                      </div>
                      <div className="mt-2 text-sm text-gray500 font-normal">
                        {
                          LocaleStrings.settings_api_documentation_foradminpanel_desc3
                        }
                      </div>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

function validate(values) {
  var errors = {};

  return errors;
}

function mapStateToProps(state, ownProps) {
  var {session, user} = state;

  return {
    session,
    user,
    isLoggedIn: state.isLoggedIn,
    isapidocumentationmodalOpen: state.isapidocumentationmodalOpen,
  };
}

export default connect(mapStateToProps, {
  apiDocumentationmodal,
})(
  reduxForm({
    validate,
    form: 'DocumentationForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Apidocumentation),
);
