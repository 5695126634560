import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { BaseComponent } from "@baseComponent";
import LocaleStrings from "@language";
import { classNames } from "@commonFunction";
import toast, { Toaster } from "react-hot-toast";
import ReactConfirmAlert, { confirmAlert } from "react-confirm-alert";
import EditApi from "./editapi";
import { onEditapi, updateApi, fetchApikeys } from "@settingsApiActions";
import { fetchAppuser } from "@sidebarActions";

class CreateApiItem extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: "",
      selectedUsers: [],
    };
  }
  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }

  openModal = (e, obj) => {
    this.props.onEditapi(obj);
    this.setState({ isOpen: true });
  };
  closeModal = () => this.setState({ isOpen: false });

  _deleteApi(e, values) {
    let { session, user } = this.props;
    var data = {
      keyid: values.keyid,
      isdeleted: 1,
      isactive: 0,
      clientid: values.clientid,
    };
    confirmAlert({
      title: LocaleStrings.settings_api_delete_title,
      message: LocaleStrings.settings_api_delete_desc,
      buttons: [
        {
          label: LocaleStrings.button_yes,
          onClick: () => {
            this.props.updateApi(this.props.session, data, (resource) => {
              if (resource.success !== 0) {
                this.props.fetchApikeys(session, (callback) => {
                  this.props.fetchAppuser(session, user?.appuserid);
                  toast.success(LocaleStrings.settings_api_delete_success);
                });
              } else {
                toast.error(LocaleStrings.common_fail_message);
              }
            });
          },
        },
        {
          label: LocaleStrings.button_no,
          onClick: () => {},
        },
      ],
    });
  }

  _handlecopy = (e, apikey) => {
    navigator.clipboard.writeText(apikey);
    toast.success(LocaleStrings.copied);
  };

  handleindex = (nmbr) => {
    if (nmbr % 2 == 0) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    var { values, keyindex } = this.props;

    let indexisodd1 = this.handleindex(keyindex);
    return (
      <>
        <tr className={classNames(indexisodd1 ? "bg-white" : "chatbg", "")}>
          <td className="whitespace-nowrap py-4 px-6 text-sm font-medium flex">
            {values.apikey}

            <img
              onClick={(e) => this._handlecopy(e, values.apikey)}
              src="../../images/copysmall.png"
              className="ml-2 cursor-pointer"></img>
          </td>
          {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">Social</td> */}
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {values.purpose}
          </td>

          <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex end-item">
            <div
              className="text-gray500 mr-4 cursor-pointer"
              onClick={(e) => this._deleteApi(e, values)}>
              {LocaleStrings.settings_api_deletebtn}
            </div>
            {/* <div className="text-primary cursor-pointer" onClick={(e) => this.openModal(e, values)}>{LocaleStrings.settings_api_editbtn}</div> */}
          </td>
        </tr>
        {/* {this.state.isOpen ? (
                    <EditApi
                        isOpen={this.state.isOpen}
                        onClose={this.closeModal}
                        data={this.props.values}
                    />
                ) : (
                    ""
                )} */}
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var { session, user } = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
  };
}

export default connect(mapStateToProps, {
  onEditapi,
  updateApi,
  fetchApikeys,
  fetchAppuser,
})(CreateApiItem);
