import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {isJSON} from '@commonFunction';
import {BaseComponent} from '@baseComponent';

class RevealedContactListitems extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      setOpen: false,
      loading: false,
      showerror: false,
      alertMessage: '',
      selectedUsers: [],
    };
  }
  componentWillReceiveProps() {
    // console.log('abcd',this.props.data)
  }

  renderphoneList = phonenumberArr => {
    let item = _.map(phonenumberArr, (val, index) => {
      return (
        <div className="flex items-center space-x-4">
          <div className="flex-1 min-w-0">
            <p className="text-sm font-medium text-gray500">{val}</p>
          </div>
        </div>
      );
    });
    return item;
  };
  renderemailList = emailArr => {
    let item = _.map(emailArr, (val, index) => {
      return (
        <div className="flex items-center space-x-4">
          <div className="flex-1 min-w-0">
            <p className="text-sm font-medium text-gray500">{val.address}</p>
          </div>
        </div>
      );
    });
    return item;
  };

  renderbusinessemailList = bemailArr => {
    let item = _.map(bemailArr, (val, index) => {
      return (
        <div className="flex items-center space-x-4">
          <div class="group cursor-pointer flex relative">
            <div class=" px-2 py-1">{val}</div>
          </div>
        </div>
      );
    });
    return item;
  };

  render() {
    var {values} = this.props;
    var selectedUsers = this.props.selectedUsers;
    var index = _.findIndex(selectedUsers, function (curuser) {
      return curuser.contactid === values.contactid;
    });

    var checked = false;
    if (index >= 0) {
      checked = true;
    }
    let phoneArr = [];
    let emailArr = [];

    let emailsjson = isJSON(values.emails);
    let phonejson = isJSON(values.phone_numbers);

    if (emailsjson) {
      emailArr = JSON.parse(values.emails);
    }
    if (phonejson) {
      phoneArr = JSON.parse(values.phone_numbers);
    }

    let business_emails = '';

    if (values.business_emails && values.business_emails != null) {
      business_emails = _.split(values.business_emails, ',');
    }

    return (
      <>
        <tr>
          <td className="whitespace-nowrap py-4 px-6 text-sm font-medium">
            {values.name}
          </td>
          {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">Social</td> */}
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {emailArr.length > 0 ? (
              <span>{this.renderemailList(emailArr)}</span>
            ) : (
              ''
            )}

            {emailArr.length == 0 ? '-' : ''}
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {business_emails.length > 0 ? (
              <span>{this.renderbusinessemailList(business_emails)}</span>
            ) : (
              ''
            )}
            {business_emails == '' ? '-' : ''}
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {phoneArr.length > 0 ? (
              <span>{this.renderphoneList(phoneArr)}</span>
            ) : (
              '-'
            )}
          </td>
          <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"></td>
        </tr>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  var {session, user} = state;
  // console.log('customerlistingtabs',state.customerlistingtabs);
  return {
    session,
    user,
  };
}

export default connect(mapStateToProps, {})(RevealedContactListitems);
