import React, {Component} from 'react';
import {connect} from 'react-redux';
import blankImg from '../../assets/images/success_placeholder.svg';
import LocaleStrings from '@language';
import {fetchsessionData} from '@mainActions';
import {fetchAppuser} from '@sidebarActions';
import {logGTMEvent} from '@commonFolder/gtm-event';

class PlanSuccess extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    // All Binded Functions
  }

  componentDidMount() {
    let {session, user} = this.props;
    let sessionobj = {};
    if (session && session.sessionToken && session.sessionToken != '') {
      sessionobj.session_token = session.sessionToken;
      this.props.fetchsessionData(sessionobj, '', response => {
        console.log('SUCCESS RESPONSE => ', response);
        this.props.fetchAppuser(sessionobj, user.appuserid, data => {
          const appuserData = data.resource;
          let name = '';

          if (
            appuserData &&
            appuserData.data &&
            appuserData.data.length > 0 &&
            appuserData.data[0].client_by_clientid
          ) {
            if (
              appuserData.data[0]?.client_by_clientid
                ?.usersubscription_by_clientid &&
              appuserData.data[0]?.client_by_clientid
                ?.usersubscription_by_clientid.length > 0 &&
              appuserData.data[0]?.client_by_clientid
                ?.usersubscription_by_clientid[0]?.subscriptionplan_by_planid
            ) {
              let subscriptionplan_by_planid =
                appuserData.data[0]?.client_by_clientid
                  ?.usersubscription_by_clientid[0]?.subscriptionplan_by_planid;

              name = subscriptionplan_by_planid?.name.toLowerCase();

              logGTMEvent({
                event: 'platformSubscribed',
                method: 'subscribed',
                plan: name,
              });
            }
          }
        });
      });
    }
  }

  handlePlanPage() {
    this.props.history.push('/dashboard/main/dashboardmain');
  }

  render() {
    let {appuserData} = this.props;

    let name = '';

    if (
      appuserData &&
      appuserData.data &&
      appuserData.data.length > 0 &&
      appuserData.data[0].client_by_clientid
    ) {
      if (
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid
          .length > 0 &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid[0]
          ?.subscriptionplan_by_planid
      ) {
        let subscriptionplan_by_planid =
          appuserData.data[0]?.client_by_clientid
            ?.usersubscription_by_clientid[0]?.subscriptionplan_by_planid;

        name = subscriptionplan_by_planid?.name.toLowerCase();
      }
    }
    return (
      <>
        <main className="scrollbar-hide xs:px-6 sm:px-12 lg:px-12 py-10 overflow-y-auto bg-homegray">
          <div className="flex items-center justify-center h-5/6">
            <div className="text-center">
              <img className="inline mb-3" src={blankImg} />
              <h2 className="text-2xl font-normal text-primary">
                {LocaleStrings.pay_success_title1} {name} plan
              </h2>
              <div className=" grid grid-cols-1 md:grid-cols-3 ">
                <div></div>
                <div>
                  <button
                    className="btn-primary mt-6 w-64"
                    onClick={() => this.handlePlanPage()}>
                    {LocaleStrings.pay_btn_gotoplan}
                  </button>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

function mapStateToProps(state) {
  var {session, user} = state;
  return {
    session,
    user,
    subscriptionplanList: state.subscriptionplanList,
    appuserData: state.appuserData,
  };
}
export default connect(mapStateToProps, {
  fetchsessionData,
  fetchAppuser,
})(PlanSuccess);
