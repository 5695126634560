import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import reduxReset from 'redux-reset';
import reducers from './reducers';
import {loadingBarMiddleware} from 'react-redux-loading-bar';
import {errorBarMiddleware} from './common/errorbar/index.js';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {loadState, saveState} from './localStorage';

import TagManager from 'react-gtm-module';

var persistedState = loadState();

// var createStoreWithMiddleware = compose(applyMiddleware(thunk))(createStore);
var createStoreWithMiddleware = compose(
  applyMiddleware(thunk, loadingBarMiddleware(), errorBarMiddleware()),
  reduxReset(),
)(createStore);

export var store = createStoreWithMiddleware(reducers, persistedState);
store.subscribe(() => {
  saveState(store.getState());
});

const tagManagerArgs = {
  gtmId: 'GTM-KZZBMH32',
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
