import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import LocaleStrings from '@language';
import toast, {Toaster} from 'react-hot-toast';
import CreateApi from './createapi';
import {randomstring} from 'randomstring-js';
import BaseLoader from '@baseLoader';
import CreateApiItem from './api-item';
import TryoutApi from './tryoutapi';
import Apidocumentation from './documentation';
import {
  createApimodal,
  fetchApikeys,
  tryoutapiModal,
  apiDocumentationmodal,
} from '@settingsApiActions';
import {fetchAppuser} from '@sidebarActions';
import {ENTERPRISE_MONTHLY, ENTERPRISE_YEARLY} from '@constant';
import {INSTANCE_URL} from '../../../../../../common/constants';

class Api extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_currency,
      apikey: '',
      loading: true,
    };

    // All Binded Functions
  }

  componentDidMount() {
    let {session, user} = this.props;
    this.props.fetchAppuser(session, user?.appuserid);
    this.props.fetchApikeys(session, callback => {
      this.setState({loading: false});
    });
  }

  opencreateApi = () => {
    let randomstring16 = randomstring(16);
    this.setState({apikey: randomstring16});
    this.props.createApimodal(true);
  };

  opentryoutApi = () => {
    this.props.tryoutapiModal(true);
  };
  openDocumentationModal = () => {
    this.props.apiDocumentationmodal(true);
  };

  renderCreateapiList() {
    var items = _.map(this.props.apikeysDatalist.data, (values, index) => (
      <CreateApiItem
        keyindex={index}
        values={values}
        // pagination={this.paginationCallback}
        // pageDataLength={pageDataLength}
        // currentPage={pageNumber}
      />
    ));
    return items;
  }

  _openUpgradPlan(e) {
    this.props.history.push('/dashboard/upgradeplan');
  }

  render() {
    let {apikeysDatalist, appuserData} = this.props;
    let apicallcount = '';
    let planid = '';
    if (
      appuserData &&
      appuserData.data &&
      appuserData.data.length > 0 &&
      appuserData.data[0].client_by_clientid
    ) {
      if (
        appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid.length >
          0
      ) {
        let usercredit_by_clientid =
          appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid[0];
        apicallcount = parseInt(usercredit_by_clientid.apicallcount);
      }

      if (
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid
          .length > 0 &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid[0]
          ?.subscriptionplan_by_planid
      ) {
        let subscriptionplan_by_planid =
          appuserData.data[0]?.client_by_clientid
            ?.usersubscription_by_clientid[0]?.subscriptionplan_by_planid;
        planid = subscriptionplan_by_planid.planid;
      }
    }

    // planid = PROFESSIONAL_YEARLY;
    // apicallcount = 5000;

    // console.log('PLAN ID => ', planid);

    return (
      <>
        <div className="xs:px-6 md:px-0">
          <div className="block md:flex pb-10">
            <div>
              <div className="text-mainGray text-lg font-medium">
                {LocaleStrings.settings_api_header}
              </div>
              {planid === ENTERPRISE_YEARLY || planid === ENTERPRISE_MONTHLY ? (
                <div className="mt-2 text-gray500 text-sm font-normal">
                  {LocaleStrings.settings_api_subheader}
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="ml-auto">
              <div className="flex">
                {apicallcount !== '' &&
                apicallcount > 0 &&
                (planid === ENTERPRISE_YEARLY ||
                  planid === ENTERPRISE_MONTHLY) ? (
                  <button
                    type="button"
                    style={{width: 'auto'}}
                    onClick={e => this.opencreateApi(e)}
                    className="btn-primary  cursor-pointer">
                    {LocaleStrings.settings_api_createapibtn}
                  </button>
                ) : (
                  ''
                )}
                {!(
                  planid === ENTERPRISE_YEARLY || planid === ENTERPRISE_MONTHLY
                ) ? (
                  <button
                    type="submit"
                    style={{width: 'auto'}}
                    onClick={e => this._openUpgradPlan()}
                    className="btn-primary ml-4 cursor-pointer">
                    Upgrade Now
                  </button>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          {/* <div class="w-full  mx-auto bg-white shadow-lg rounded-md border border-gray-200">
                    <header class="px-5 py-2 border-b border-gray-100">
                        <div className="flex">
                            <div className="relative w-full text-ternary focus-within:text-secondary ">
                                <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none px-3">

                                </div>
                                <input
                                    id="search-field"
                                    className="block lg:w-4/12 border-gray-300 xs:w-full sm:w-full h-full pl-8 pr-3 py-2 border-transparent text-secondary placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm rounded-md"
                                    placeholder={"Search"}
                                    type="search"
                                    name="search"
                                    value={this.state.search}
                                    onChange={this.handleSearch}
                                />
                            </div>
                            <div className="ml-auto">
                                <button className="btn-light-primary">{LocaleStrings.settings_api_authorizebtn}</button>
                            </div>
                        </div>
                    </header>
                </div> */}
          {this.state.loading ? (
            <>
              <BaseLoader data={this.state.loaderDesc} />
            </>
          ) : (
            <>
              {apikeysDatalist &&
              apikeysDatalist.data &&
              apikeysDatalist.data.length > 0 &&
              (planid === ENTERPRISE_YEARLY ||
                planid === ENTERPRISE_MONTHLY) ? (
                <>
                  <div className="flex flex-col pr-1">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                          <table className="min-w-full table-fixed divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-medium text-gray500">
                                  {LocaleStrings.settings_api_th1}
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-medium text-gray500">
                                  {LocaleStrings.settings_api_th2}
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-medium text-gray500"></th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              {this.renderCreateapiList()}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-1  mt-5">
                    <div className="cols-span-6 pr-1">
                      <div className="overflow-hidden bg-white shadow sm:rounded-lg py-4 px-6">
                        <div>{LocaleStrings.settings_api_tryout_api}</div>
                        <div className="grid xs:grid-cols-1 md:grid-cols-2  mt-5 pb-2">
                          <div className="cols-span-10 pr-1">
                            <div>Method: GET</div>
                            <div>
                              {' '}
                              {`${INSTANCE_URL}${LocaleStrings.settings_api_apicallurl}`}
                            </div>
                          </div>
                          <div className="cols-span-2 pr-1 ml-auto xs:mt-4 sm:mt-0">
                            <div className="flex">
                              <button
                                className="btn-primary"
                                style={{width: '180px'}}
                                onClick={e => this.openDocumentationModal(e)}>
                                How to use
                              </button>
                              <button
                                className="btn-primary ml-3"
                                style={{width: '150px'}}
                                onClick={e => this.opentryoutApi(e)}>
                                {LocaleStrings.settings_api_tryout}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {!(
                    planid === ENTERPRISE_YEARLY ||
                    planid === ENTERPRISE_MONTHLY
                  ) ? (
                    <div className="center-item lg:my-48 text-lg text-ternary">
                      Unlock the Power of Our API!
                    </div>
                  ) : (
                    <div className="center-item lg:my-48 text-lg text-ternary">
                      {LocaleStrings.no_data_found}
                    </div>
                  )}
                </>
              )}
            </>
          )}
          <CreateApi apikey={this.state.apikey} />
          <TryoutApi {...this.props} />
          <Apidocumentation />
          <Toaster />
        </div>
      </>
    );
  }
}

var mapStateToProps = state => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  apikeysDatalist: state.apikeysDatalist,
  appuserData: state.appuserData,
});

export default connect(mapStateToProps, {
  createApimodal,
  fetchApikeys,
  fetchAppuser,
  tryoutapiModal,
  apiDocumentationmodal,
})(Api);
