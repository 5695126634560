import React, { Component } from "react";
import { connect } from "react-redux";
import LocaleStrings from "@language";
import _ from "lodash";
import moment from "moment";
import { converDateIntoLocal, classNames } from "@commonFunction";
import BaseLoader from "@baseLoader";
import { addMembermodal } from "@manageseatAction";
import MemberAddEdit from "./memberaddedit";
import MemberListitems from "./memberlist-item";
import { fetchUserexistingplan } from "@mainSettingsActions";
import { fetchMembers } from "@manageseatAction";
import { fetchAppuser } from "@sidebarActions";
import { PROFESSIONAL_YEARLY } from "@constant";

class Manageseats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loaderDesc: LocaleStrings.preparing_members,
    };

    // All Binded Functions
  }
  componentDidMount() {
    let { user, session } = this.props;
    if (this.props.user) {
      let usercurrentplanid = 0;

      if (
        user &&
        user.usersubscription_by_clientid &&
        user.usersubscription_by_clientid.length > 0
      ) {
        usercurrentplanid =
          user.usersubscription_by_clientid[0]?.subscriptionplan_by_planid
            ?.planid;
      }
      let appuserid = user?.appuserid;
      this.props.fetchAppuser(session, appuserid, (callback) => {});
      if (usercurrentplanid !== 0) {
        this.props.fetchUserexistingplan(session, usercurrentplanid);
      }

      this.props.fetchMembers(this.props.session, (callback) => {
        this.setState({ loading: false });
      });
    }
  }

  openAddmember() {
    this.props.addMembermodal(true);
  }
  openUpgradePlan() {
    this.props.history.push("/dashboard/upgradeplan");
  }

  rendermemberList() {
    var items = _.map(this.props.memberList.data, (values, index) => (
      <MemberListitems
        keyindex={index}
        values={values}
        // pagination={this.paginationCallback}
        // pageDataLength={pageDataLength}
        // currentPage={pageNumber}
      />
    ));
    return items;
  }

  render() {
    let { memberList, userexistingplanData, user, appuserData } = this.props;

    let name = "";
    let price = "";
    let details = "";
    let availableseats = "";
    let sku = "";
    let contactcredit = "";
    let expirydate = "";
    let planid = "";
    const otherPlan = ["custom_trial", "custom_monthly", "custom_yearly", null];
    if (
      userexistingplanData &&
      userexistingplanData.data &&
      userexistingplanData.data.length > 0
    ) {
      // name = userexistingplanData.data[0].name;
      // price = userexistingplanData.data[0].price;
      // details = userexistingplanData.data[0].details;
      // availableseats = userexistingplanData.data[0].availableseats;
    }

    if (
      appuserData &&
      appuserData.data &&
      appuserData.data.length > 0 &&
      appuserData.data[0].client_by_clientid
    ) {
      let client_by_clientid = appuserData.data[0]?.client_by_clientid;
      if (
        appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid.length >
          0
      ) {
        let usercredit_by_clientid =
          appuserData.data[0]?.client_by_clientid?.usercredit_by_clientid[0];
        availableseats = usercredit_by_clientid.seatcount;
        contactcredit = usercredit_by_clientid.contactcredit;
      }

      if (
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid
          .length > 0 &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid[0]
          ?.subscriptionplan_by_planid
      ) {
        let subscriptionplan_by_planid =
          appuserData.data[0]?.client_by_clientid
            ?.usersubscription_by_clientid[0]?.subscriptionplan_by_planid;
        let usersubscription_by_clientid =
          appuserData.data[0]?.client_by_clientid
            ?.usersubscription_by_clientid[0];
        expirydate = converDateIntoLocal(
          usersubscription_by_clientid.expirydate
        ).format("D MMM YYYY");
        name = subscriptionplan_by_planid.name;
        sku = subscriptionplan_by_planid.sku;
        price = subscriptionplan_by_planid.price;
        details = subscriptionplan_by_planid.details;
        planid = subscriptionplan_by_planid.planid;
      }
    }

    let currentDate = moment();
    let expired = false;
    if (expirydate !== "") {
      if (currentDate.isAfter(moment(expirydate))) {
        expired = true;
      }
    }

    let stripeplan = false;
    if (
      appuserData &&
      appuserData.data &&
      appuserData.data.length > 0 &&
      user.usersubscription_by_clientid &&
      user.usersubscription_by_clientid.length > 0
    ) {
      if (expired !== true && contactcredit > 0) {
        if (!otherPlan.includes(sku)) {
          stripeplan = true;
        }
      }
    }

    return (
      <>
        {appuserData &&
        appuserData.data &&
        appuserData.data.length > 0 &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid &&
        appuserData.data[0]?.client_by_clientid?.usersubscription_by_clientid
          .length > 0 ? (
          <>
            {this.state.loading ? (
              <>
                <BaseLoader data={this.state.loaderDesc} />
              </>
            ) : (
              <>
                <div className="manage-seats xs:pl-4 md:pl-0 md:pr-1">
                  <div className="manageseat header bg-primaryHover w-full rounded-lg px-4 py-8 block md:flex">
                    <div>
                      <img src="../../images/settingfeatureicon.png"></img>
                    </div>
                    <div className="pl-5">
                      <div className=" block md:flex">
                        <div className="text-primary font-medium text-base">
                          {name}
                        </div>
                        <div className="md:pl-2 text-gray500 font-medium">
                          ${price}
                          {LocaleStrings.setting_manageseats_amount}
                        </div>
                      </div>
                      <div className="mt-1 text-gray500 font-medium">
                        {details}
                      </div>
                    </div>

                    <div className="ml-auto mt-2 ">
                      <button
                        type="button"
                        className="btn-white text-primary cursor-pointer"
                        onClick={(e) => this.openUpgradePlan(e)}>
                        {/* {LocaleStrings.viewplan} */}
                        {planid == PROFESSIONAL_YEARLY
                          ? "View Plans"
                          : "Upgrade"}
                      </button>
                    </div>
                  </div>
                  <div className="block md:flex mt-10">
                    <div className="text-lg font-medium text-mainGray pt-1">
                      {LocaleStrings.setting_manageseats_teammembers}
                    </div>
                    <div className="text-primary text-sm ml-2 bg-primaryHover px-6 center text-center rounded-2xl pt-2 my-4 md:my-0 py-4 md:py-2">
                      {/* <div className="text-primary text-sm my-4 md:my-0 ml-2 bg-primaryHover py-4 md:py-0 px-6 center text-center rounded-2xl pt-2"> */}
                      {availableseats} Seats Available
                    </div>
                    {availableseats == 0 ? (
                      ""
                    ) : (
                      <div className="ml-auto">
                        <button
                          type="button"
                          onClick={(e) => this.openAddmember(e)}
                          className="btn-primary cursor-pointer">
                          <img
                            src="../../images/addicon.png"
                            className="px-2 pt-1"></img>
                          {LocaleStrings.setting_manageseats_btn_addmember}
                        </button>
                      </div>
                    )}
                  </div>
                  {memberList &&
                  memberList.data &&
                  memberList.data.length > 0 ? (
                    <>
                      <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                              <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray500 sm:pl-6">
                                      {
                                        LocaleStrings.setting_manageseats_table_th1
                                      }
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3.5 text-left text-sm font-medium text-gray500">
                                      {
                                        LocaleStrings.setting_manageseats_table_th2
                                      }
                                    </th>
                                    <th
                                      scope="col"
                                      className="px-3 py-3.5 text-left text-sm font-medium text-gray500">
                                      {
                                        LocaleStrings.setting_manageseats_table_th3
                                      }
                                    </th>
                                    {/* <th
                                      scope="col"
                                      className="px-3 py-3.5 text-left text-sm font-medium text-gray500"
                                    >
                                      {
                                        LocaleStrings.setting_manageseats_table_th4
                                      }
                                    </th> */}
                                    <th
                                      scope="col"
                                      className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-sm font-medium text-gray500">
                                      {
                                        LocaleStrings.setting_manageseats_table_th5
                                      }
                                    </th>
                                    <th
                                      scope="col"
                                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"></th>
                                  </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                  {this.rendermemberList()}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="center-item lg:my-48 text-lg text-ternary">
                      {LocaleStrings.no_data_found}
                    </div>
                  )}
                </div>
                <MemberAddEdit />
              </>
            )}
          </>
        ) : (
          <div className="center-item lg:my-48 text-lg text-ternary">
            Please Add a Plan to add members
          </div>
        )}
      </>
    );
  }
}

var mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn,
  user: state.user,
  session: state.session,
  memberList: state.memberList,
  userexistingplanData: state.userexistingplanData,
  appuserData: state.appuserData,
});

export default connect(mapStateToProps, {
  addMembermodal,
  fetchMembers,
  fetchUserexistingplan,
  fetchAppuser,
})(Manageseats);
